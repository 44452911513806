import React, { useState, useEffect } from "react";
import Picklist from "../components/Picklist";
import PlusBtn from "../images/plusJobs-min.png";
import { Tooltip } from "react-tippy";
import MinusBtn from "../images/minusJobs-min.png";
import BaronaLogo from "../images/baronalogo.webp";
import ArffmanLogo from "../images/arffman.png";
import linkIcon from "../images/share.png";
import NoUser from "../images/user.png";
import LoadingSpinner from "../components/Misc/LoadingSpinner";
import {
  Ammattialat,
  Ammatit,
  Lupakortit_ja_osaamispassit,
  Ty_kokemusta_yhteens,
  Tyonhakualat,
  Hakualueet,
  Palkkatoive,
  Paikkakunnat,
  HaettavatTeht,
  Asema,
  Ty_nhaun_prioriteetti,
  Tyomatka,
  Rajoitteet,
  Ajokortti,
  Tyokokeilu,
  Palkkatuki,
  Vaihtoehdot,
  Tyolupa,
  Auto,
  Ty_lupa_ja_pankkitili,
  Koulutusaste,
  Koulutusala,
} from "../picklists";
import Dropdown from "../components/Dropdown";
import Textbox from "../components/Textbox";
import EditBtn from "../images/edit.png";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RadioButton from "../components/RadioButton";
import DataConsent from "../components/DataConsent";
import DocsEmbed from "../components/GoogleDocs/DocsEmbed";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  ColumnsGap,
  InfoCircleFill,
  CaretDownFill,
  CaretRightFill,
} from "react-bootstrap-icons";
import { Collapse, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useTranslation, withTranslation, Trans } from "react-i18next";
import "../i18n";
import {
  Grid,
  Typography,
  Button,
  Divider,
  CircularProgress,
  useTheme,
} from "@mui/material";
import Container from "@mui/material/Container";
import { Paper, Box, IconButton } from "@mui/material";
import { alignPropType } from "react-bootstrap/esm/types";

function HakuProfiili() {
  const [rekryData, setRekryData] = useState();
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(null);
  const [modifiedData, setModifiedData] = useState(null);
  const [originalData, setOriginalData] = useState(null);
  const [editCount, setEditCount] = useState(0);
  const [omatTiedotShow, setOmatTiedotShow] = useState(false);
  const [jobAds, setJobAds] = useState([]);
  const [jobAdsShow, setJobAdsShow] = useState(false);
  const [showCollapse, setShowCollapse] = useState([]);
  const [notApprovedWorkSearchs, setNotApprovedWorkSearchs] = useState([]);
  const [approvedWorkSearchs, setApprovedWorkSearchs] = useState([]);
  const [permissionLoading, setPermissionLoading] = useState(false);
  const [workSearchCollapse, setWorkSearchCollapse] = useState(false);
  const [permission, setPermission] = useState(
    sessionStorage.getItem("permission")
  );
  const [progressValue, setProgressValue] = useState(0);

  const { t, i18n } = useTranslation();

  const navigate = useNavigate();

  const notifySuccess = () =>
    toast.success("Tiedot tallennettu!", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const notifyError = () =>
    toast.error(
      "Virhe: Yritä uudelleen tai ota yhteyttä palveluasiantuntijaasi.",
      {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );

  const notifySelectFirst = () =>
    toast.error("Virhe: Valitse Kyllä tai Ei kaikkiin yrityksiin.", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const notifyNoPermitSet = () =>
    toast.error(
      "Et ole asettanut vielä tietojenkäsittelylupaasi. Paina ruudun alareunassa olevaa Asetukset nappulaa asettaaksesi tietojenkäsittelyluvan.",
      {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );

  useEffect(() => {
    if (sessionStorage.getItem("userData") && rekryData) {
      //console.log(sessionStorage.getItem("userData"))
      if (rekryData.professions.value && rekryData.haettavatTeht.value)
        getJobAds();
      else {
        setJobAds(false);
      }
    }
  }, [rekryData]);

  useEffect(() => {
    getWorkSearch();
  }, []);

  const toggleCollapse = (index, jobAd) => {
    let collapseCopy = [...showCollapse];
    collapseCopy[index] = !collapseCopy[index];

    let obj = { source: jobAd.source, title: jobAd.adTitle };
    if (collapseCopy[index]) {
      const response = fetch(
        "/api/misc/jobAdOpen/" + sessionStorage.getItem("id"),
        {
          method: "POST",
          body: JSON.stringify(obj),
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then(function (response) {
          return response.text();
        })
        .then(function (data) {});
    }

    setShowCollapse(collapseCopy);
  };

  async function getJobAds() {
    await fetch("/api/misc/jobads/" + sessionStorage.getItem("id"))
      .then(function (response) {
        if (response.ok) {
          return response.json();
        }
      })
      .then(async function (data) {
        let jobsFromArea = [];
        let userAreas = rekryData.hakuAlueet
          ? rekryData.hakuAlueet.value.toString()
          : "";

        let userCities = sessionStorage.getItem("userData").paikkaKunnat
          ? sessionStorage.getItem("userData").paikkaKunnat.value.split(", ")
          : "";

        for (let i = 0; i < data.length; i++) {
          let jobAdCities = data[i].cities.split(",");
          if (
            (data[i] &&
              data[i].areas &&
              data[i].areas.some((item) => userAreas.includes(item))) ||
            (jobAdCities &&
              jobAdCities &&
              jobAdCities.some((item) => userCities.includes(item)))
          ) {
            jobsFromArea.push(data[i]);
          }
        }

        setJobAds(jobsFromArea);
      })
      .catch((error) => {
        console.log("error: " + error);
      });
  }

  async function getRekryData(action) {
    let parsedData;
    if (action === "reFetch") {
      sessionStorage.removeItem("rekryData");
    }
    if (sessionStorage.getItem("rekryData")) {
      //console.log("ei ajeta?")
      parsedData = sessionStorage.getItem("rekryData");
      setRekryDataFunction(parsedData);
    } else {
      await fetch("/api/user/rekrydata/" + sessionStorage.getItem("id"))
        .then(function (response) {
          if (response.ok) {
            return response.json();
          }
        })
        .then(async function (data) {
          //console.log("asdasd",data)
          setRekryDataFunction(data);
        })
        .catch((error) => {
          console.log("error: " + error);
        });
    }
  }

  async function getWorkSearch(action) {
    await fetch("/api/user/workSearch/" + sessionStorage.getItem("id"))
      .then(function (response) {
        if (response.ok) {
          return response.json();
        }
      })
      .then(async function (data) {
        setNotApprovedWorkSearchs(data.response.emptyArr);
        setApprovedWorkSearchs(data.response.resArr);
      })
      .catch((error) => {
        console.log("error: " + error);
      });
  }

  function handleShowModal() {
    setShow(true);
  }

  function handleCloseModal() {
    setShow(false);
  }

  function setRekryDataFunction(data) {
    let parsedData = JSON.parse(data);
    let DataPermitBool = JSON.parse(sessionStorage.getItem("userData"));
    if (!DataPermitBool.dataPermit.value) notifyNoPermitSet();
    setRekryData(parsedData);
    setOriginalData(JSON.stringify(parsedData));
    sessionStorage.setItem("rekryData", data);
    let counter = 0;

    let fillableData = [
      "ajoKortti",
      "asema",
      "haettavatTeht",
      "hakuAlat",
      "hakuAlueet",
      "liikkuminen",
      "mahdPalkkatuki",
      "mahdTyokokeilu",
      "omaKuvaus",
      "onkoAuto",
      "paikkaKunnat",
      "palkkaToive",
      "prioriteetti",
      "rajoitteet",
      "tyoLupa",
      "tyonHakija",
      "vaihtoEhdot",
      "education",
      "eduField",
      "eduLevel",
      "fields",
      "passes",
      "professions",
      "experience",
    ];

    for (const item in parsedData) {
      if (parsedData[item].value) {
        if (fillableData.includes(item.toString())) {
          counter++;
        }
      }
    }
    let rekryCounter = counter;
    let rekryLength = 24;
    setProgressValue(Math.round((rekryCounter / rekryLength) * 100));
  }

  const handleJobAdLogoClick = (jobAd) => {
    let obj = { source: jobAd.source, title: jobAd.adTitle };
    const response = fetch(
      "/api/jobAdLinkClick/" + sessionStorage.getItem("id"),
      {
        method: "POST",
        body: JSON.stringify(obj),
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then(function (response) {
        return response.text();
      })
      .then(function (data) {});
  };

  function handlePermissionSubmit() {
    let check = true;
    for (let i = 0; notApprovedWorkSearchs.length > i; i++) {
      if (notApprovedWorkSearchs[i].selected !== true) {
        check = false;
        break;
      }
    }

    if (check) {
      setPermissionLoading(true);
      const response = fetch(
        "/api/misc/workSearchPermissionSubmit/" + sessionStorage.getItem("id"),
        {
          method: "POST",
          body: JSON.stringify(notApprovedWorkSearchs),
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then(function (response) {
          return response;
        })
        .then(function (data) {
          notifySuccess();
          getWorkSearch();
          setPermissionLoading(false);
        })
        .catch((error) => {
          setPermissionLoading(false);
          console.log("error: " + error);
          notifyError();
        });
    } else notifySelectFirst();
  }

  function handleSubmit(value) {
    setLoading(true);

    const response = fetch(
      "/api/user/submitrekrydata/" + sessionStorage.getItem("id"),
      {
        method: "POST",
        body: JSON.stringify(rekryData),
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        //console.log(data);
        if (data.toString() === "ok") {
          getRekryData("reFetch");
          setLoading(false);
          setModifiedData(false);
          notifySuccess();
          setEditCount(0);
        }
      })
      .catch((error) => {
        console.log("error: " + error);
        setLoading(false);
        notifyError();
        setEditCount(0);
      });
  }
  if (rekryData) {
  }

  function editFunction(choice) {
    if (!loading) setModifiedData(true);
    setEditCount(editCount + 1);
    setRekryData((rekryData) => {
      return {
        ...rekryData,
        [choice.objName]: { ...choice, checked: !choice.checked },
      };
    });
  }

  function cancelEdit(choice) {
    setEditCount(editCount - 1);
    let orig = JSON.parse(originalData);
    let compare = orig;
    orig = orig[choice.objName].value;
    setRekryData((rekryData) => {
      return {
        ...rekryData,
        [choice.objName]: { ...choice, checked: false, value: orig },
      };
    });
    if (editCount === 1) setModifiedData(false);
  }

  function editValue(choice, e) {
    setRekryData((rekryData) => {
      return { ...rekryData, [choice.objName]: { ...choice, value: e } };
    });
  }

  function deleteFromList(e, choice) {
    //console.log("choice", choice, "e", e)
    let newList = rekryData[choice].value;
    rekryData[choice].value.forEach((element, index) => {
      if (element === e) {
        newList.splice(index, 1);
        // console.log("test", newList)
      }
      setRekryData((rekryData) => {
        return {
          ...rekryData,
          [choice.objName]: { ...choice, value: newList },
        };
      });
    });
  }

  function setPermissions(item, action, index) {
    console.log(action);

    const handleUpdate = (index, single, action) => {
      const newWorkSearchs = [...notApprovedWorkSearchs];
      newWorkSearchs[index] = {
        ...single,
        ["lupa"]: action,
        ["selected"]: true,
      };
      setNotApprovedWorkSearchs(newWorkSearchs);
    };

    if (action === "yes") {
      handleUpdate(index, item, true);
    }
    if (action === "no") {
      handleUpdate(index, item, false);
    }
  }

  function AddToList(e, choice) {
    //console.log("e", e, "choice", choice)
    let newList;
    if (rekryData[choice.objName].value) {
      newList = rekryData[choice.objName].value;
    } else {
      newList = [];
    }
    let check = false;
    if (newList.length > 0) {
      newList.forEach((element) => {
        if (element === e) {
          check = true;
        }
      });
    }

    if (!check) {
      newList.push(e);
      setRekryData((rekryData) => {
        return {
          ...rekryData,
          [choice.objName]: { ...choice, value: newList },
        };
      });
    }
  }

  function editSingleSelect(e, choice) {
    //console.log("e", e, "choice", choice)
    setRekryData((rekryData) => {
      return { ...rekryData, [choice.objName]: { ...choice, value: e } };
    });
  }
  /*


        <div className="percentH2Div">
          <div id="rekryProgressPage" className="progressBall">
            <span className="progress-value" id="rekry-progress-value"></span>
          </div>
          <Typography sx={{ typography: { sm: "h2", xs: "h5" } }}>
            {t("rekry.title")}
          </Typography>
          <div
            style={{
              placeSelf: "center",
              paddingLeft: "6px",
              height: "100%",
              marginBottom: "-1.4vh",
            }}
          >
            <a
              className="header-info-link"
              onClick={() => setOmatTiedotShow(true)}
            >
              <InfoCircleFill color="black" size="18" />
            </a>
          </div>{" "}
          {omatTiedotShow ? (
            <DocsEmbed
              setOmatTiedotShow={setOmatTiedotShow}
              show={omatTiedotShow}
              elementId={"_tli8haywgedf"}
            />
          ) : (
            <></>
          )}
          <div id="emptyHeadDiv"></div>
        </div>
  */

  //<button id="contractBtn" onClick="window.location.href = '/tyosopimus.html'">Lisää työsopimus</button>

  async function getUserData(action) {
    await fetch("/api/user/userdata/" + sessionStorage.getItem("id") + "/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        //console.log(response)
        if (response.ok) {
          return response.json();
        }
      })
      .then(async function (data) {
        setUserDataFunction(data, action);
      })
      .catch((error) => {
        console.log("error: " + error);
      });
  }

  async function setUserDataFunction(data, action) {
    let parsedData = JSON.parse(data);

    if (parsedData.photo === "ok") {
      const response = await fetch(
        "/api/photo/getuserphoto/" + sessionStorage.getItem("id") + "/",
        {
          method: "GET",
        }
      )
        .then(function (response) {
          //console.log(response)
          if (response.ok) {
            return response.blob();
          }
        })
        .then((myBlob) => {});
    } else {
      sessionStorage.setItem("userPhoto", NoUser);
    }
    sessionStorage.setItem("name", parsedData.recordFirstName.value);
    sessionStorage.setItem("userData", data);
    let counter = 0;
  }

  useEffect(() => {
    async function fetchData() {
      if (!sessionStorage.getItem("userData")) {
        await getUserData();
      }
      getRekryData();
    }

    fetchData();
  }, []);



  return (
    <>
      <Container id="tableContainerRekry">
        <Grid container sx={{ marginTop: "20px" }}>
          <Grid item lg={6} md={12} xs={12}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                placeContent: "space-between",
                height: "100%",
              }}
            >
              <Box>
                <Typography sx={{ marginBottom: "15px" }} variant="h3">
                  {t("rekry.tayttoaste")}
                </Typography>
                <Box
                  sx={{
                    position: "relative",
                    display: "inline-flex",
                    //marginBottom:'40px'
                  }}
                >
                  <CircularProgress
                    sx={{ width: "200px!important", height: "200px!important" }}
                    variant="determinate"
                    value={progressValue}
                  />
                  <Box
                    sx={{
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0,
                      position: "absolute",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      variant="h3"
                      component="div"
                      color="text.secondary"
                    >
                      {`${Math.round(progressValue)}%`}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box>
                <Paper style={{ height: "100%" }}>
                  <table id="personalData2" className="content-table">
                    <thead></thead>
                    <tbody>
                      <DataConsent
                        show={show}
                        handleCloseModal={handleCloseModal}
                        notifyError={notifyError}
                        notifySuccess={notifySuccess}
                      />

                      <tr id="textType">
                        <td id="pointer">
                          <Typography variant="body1">
                            {t("rekry.hakualueet")}
                          </Typography>
                        </td>

                        <td className="editTd" id="Mobile">
                          <Dropdown
                            userData={rekryData}
                            field={"hakuAlueet"}
                            AddToList={AddToList}
                            deleteFromList={deleteFromList}
                            handleSubmit={handleSubmit}
                            editFunction={editFunction}
                            cancelEdit={cancelEdit}
                            picklist={Hakualueet}
                            permission={permission}
                          />
                        </td>
                      </tr>

                      <tr id="textType">
                        <td id="pointer">
                          <Typography variant="body1">
                            {t("rekry.paikkakunnat")}
                          </Typography>
                        </td>

                        <td className="editTd" id="Mailing_Street">
                          <Dropdown
                            userData={rekryData}
                            field={"paikkaKunnat"}
                            AddToList={AddToList}
                            deleteFromList={deleteFromList}
                            handleSubmit={handleSubmit}
                            editFunction={editFunction}
                            cancelEdit={cancelEdit}
                            picklist={Paikkakunnat}
                            permission={permission}
                          />
                        </td>
                      </tr>

                      <tr id="multi">
                        <td id="pointer">
                          <Typography variant="body1">
                            {t("rekry.ajokortti")}
                          </Typography>
                        </td>

                        <td className="editTd" id="Ammattialat">
                          <Dropdown
                            userData={rekryData}
                            field={"ajoKortti"}
                            AddToList={AddToList}
                            deleteFromList={deleteFromList}
                            handleSubmit={handleSubmit}
                            editFunction={editFunction}
                            cancelEdit={cancelEdit}
                            picklist={Ajokortti}
                            permission={permission}
                          />
                        </td>
                      </tr>

                      <tr id="multi">
                        <td id="pointer">
                          <Typography variant="body1">
                            {t("rekry.auto")}
                          </Typography>
                        </td>

                        <td className="editTd" id="Lupakortit_ja_osaamispassit">
                          <Picklist
                            userData={rekryData}
                            field={"onkoAuto"}
                            AddToList={AddToList}
                            deleteFromList={deleteFromList}
                            handleSubmit={handleSubmit}
                            editFunction={editFunction}
                            cancelEdit={cancelEdit}
                            editSingleSelect={editSingleSelect}
                            picklist={Auto}
                            permission={permission}
                          />
                        </td>
                      </tr>

                      <tr id="textType">
                        <td id="pointer">
                          <Typography variant="body1">
                            {t("rekry.haettavatTeht")}
                          </Typography>
                        </td>

                        <td className="editTd" id="Mailing_Zip">
                          <Dropdown
                            userData={rekryData}
                            field={"haettavatTeht"}
                            AddToList={AddToList}
                            deleteFromList={deleteFromList}
                            handleSubmit={handleSubmit}
                            editFunction={editFunction}
                            cancelEdit={cancelEdit}
                            picklist={HaettavatTeht}
                            permission={permission}
                          />
                        </td>
                      </tr>

                      <tr id="textType">
                        <td id="pointer">
                          <Typography variant="body1">
                            {t("rekry.asema")}
                          </Typography>
                        </td>

                        <td className="editTd" id="Mailing_City">
                          <Picklist
                            userData={rekryData}
                            field={"asema"}
                            AddToList={AddToList}
                            deleteFromList={deleteFromList}
                            handleSubmit={handleSubmit}
                            editFunction={editFunction}
                            cancelEdit={cancelEdit}
                            editSingleSelect={editSingleSelect}
                            picklist={Asema}
                            permission={permission}
                          />
                        </td>
                      </tr>

                      <tr id="textType">
                        <td id="pointer">
                          <Typography variant="body1">
                            {t("rekry.hakualat")}
                          </Typography>
                        </td>

                        <td className="editTd" id="idinkieli">
                          <Dropdown
                            userData={rekryData}
                            field={"hakuAlat"}
                            AddToList={AddToList}
                            deleteFromList={deleteFromList}
                            handleSubmit={handleSubmit}
                            editFunction={editFunction}
                            cancelEdit={cancelEdit}
                            picklist={Tyonhakualat}
                            permission={permission}
                          />
                        </td>
                      </tr>

                      <tr id="textType">
                        <td id="pointer">
                          <Typography variant="body1">
                            {t("rekry.tyomatka")}
                          </Typography>
                        </td>

                        <td className="editTd" id="Muu_kielitaito">
                          <Picklist
                            userData={rekryData}
                            field={"liikkuminen"}
                            AddToList={AddToList}
                            deleteFromList={deleteFromList}
                            handleSubmit={handleSubmit}
                            editFunction={editFunction}
                            cancelEdit={cancelEdit}
                            editSingleSelect={editSingleSelect}
                            picklist={Tyomatka}
                            permission={permission}
                          />
                        </td>
                      </tr>

                      <tr id="textType">
                        <td id="pointer">
                          <Typography variant="body1">
                            {t("rekry.prioriteetti")}
                          </Typography>
                        </td>
                        <td className="editTd" id="Koulutus">
                          <Picklist
                            userData={rekryData}
                            field={"prioriteetti"}
                            AddToList={AddToList}
                            deleteFromList={deleteFromList}
                            handleSubmit={handleSubmit}
                            editFunction={editFunction}
                            cancelEdit={cancelEdit}
                            editSingleSelect={editSingleSelect}
                            picklist={Ty_nhaun_prioriteetti}
                            permission={permission}
                          />
                        </td>
                      </tr>

                      <tr id="single">
                        <td id="pointer">
                          <Typography variant="body1">
                            {t("rekry.palkkaToive")}
                          </Typography>
                        </td>
                        <td className="editTd" id="Koulutusaste">
                          <Picklist
                            userData={rekryData}
                            field={"palkkaToive"}
                            AddToList={AddToList}
                            deleteFromList={deleteFromList}
                            handleSubmit={handleSubmit}
                            editFunction={editFunction}
                            cancelEdit={cancelEdit}
                            editSingleSelect={editSingleSelect}
                            picklist={Palkkatoive}
                            permission={permission}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Paper>
              </Box>
            </Box>
          </Grid>

          <Grid item lg={6} md={12} xs={12}>
            <Paper style={{ height: "100%" }}>
              <table id="personalData2" className="content-table">
                <thead></thead>
                <tbody>
                  <tr id="textType">
                    <td id="pointer">
                      <Typography variant="body1">
                        {t("rekry.haetkoToita")}
                      </Typography>
                    </td>
                    <td className="editTd" id="Last_Name">
                      <RadioButton
                        userData={rekryData}
                        field={"tyonHakija"}
                        handleSubmit={handleSubmit}
                        editFunction={editFunction}
                        cancelEdit={cancelEdit}
                        editSingleSelect={editSingleSelect}
                        permission={permission}
                      />
                    </td>
                  </tr>

                  <tr id="multi">
                    <td id="pointer">
                      <Typography variant="body1">
                        {t("rekry.rajoitteet")}
                      </Typography>
                    </td>
                    <td className="editTd" id="Koulutusala">
                      <Dropdown
                        userData={rekryData}
                        field={"rajoitteet"}
                        AddToList={AddToList}
                        deleteFromList={deleteFromList}
                        handleSubmit={handleSubmit}
                        editFunction={editFunction}
                        cancelEdit={cancelEdit}
                        picklist={Rajoitteet}
                        permission={permission}
                      />
                    </td>
                  </tr>
                  <tr id="textType">
                    <td id="pointer">
                      <Typography variant="body1">
                        {t("rekry.kuvaus")}
                      </Typography>
                    </td>
                    <td className="editTd" id="Email">
                      <Textbox
                        userData={rekryData}
                        field={"omaKuvaus"}
                        handleSubmit={handleSubmit}
                        editFunction={editFunction}
                        cancelEdit={cancelEdit}
                        editValue={editValue}
                        type={"textArea"}
                        permission={permission}
                      />
                    </td>
                  </tr>

                  <tr id="textType">
                    <td id="pointer">
                      <Typography variant="body1">
                        {t("rekry.koulutus")}
                      </Typography>
                    </td>
                    <td className="editTd" id="Koulutus">
                      <Textbox
                        userData={rekryData}
                        field={"education"}
                        handleSubmit={handleSubmit}
                        editFunction={editFunction}
                        cancelEdit={cancelEdit}
                        editValue={editValue}
                        permission={permission}
                      />
                    </td>
                  </tr>

                  <tr id="multi">
                    <td id="pointer">
                      <Typography variant="body1">
                        {t("rekry.koulutusala")}
                      </Typography>
                    </td>
                    <td className="editTd" id="Koulutusala">
                      <Dropdown
                        userData={rekryData}
                        field={"eduField"}
                        AddToList={AddToList}
                        deleteFromList={deleteFromList}
                        handleSubmit={handleSubmit}
                        editFunction={editFunction}
                        cancelEdit={cancelEdit}
                        picklist={Koulutusala}
                        permission={permission}
                      />
                    </td>
                  </tr>

                  <tr id="single">
                    <td id="pointer">
                      <Typography variant="body1">
                        {t("rekry.koulutusAste")}
                      </Typography>
                    </td>

                    <td className="editTd" id="Koulutusaste">
                      <Picklist
                        userData={rekryData}
                        field={"eduLevel"}
                        AddToList={AddToList}
                        deleteFromList={deleteFromList}
                        handleSubmit={handleSubmit}
                        editFunction={editFunction}
                        cancelEdit={cancelEdit}
                        editSingleSelect={editSingleSelect}
                        picklist={Koulutusaste}
                        permission={permission}
                      />
                    </td>
                  </tr>

                  <tr id="multi">
                    <td id="pointer">
                      <Typography variant="body1">
                        {t("rekry.ammattialat")}
                      </Typography>
                    </td>
                    <td className="editTd" id="Lupakortit_ja_osaamispassit">
                      <Dropdown
                        userData={rekryData}
                        field={"fields"}
                        AddToList={AddToList}
                        deleteFromList={deleteFromList}
                        handleSubmit={handleSubmit}
                        editFunction={editFunction}
                        cancelEdit={cancelEdit}
                        picklist={Ammattialat}
                        permission={permission}
                      />
                    </td>
                  </tr>

                  <tr id="multi">
                    <td id="pointer">
                      <Typography variant="body1">
                        {t("rekry.ammatit")}
                      </Typography>
                    </td>

                    <td className="editTd" id="Ammatit">
                      <Dropdown
                        userData={rekryData}
                        field={"professions"}
                        AddToList={AddToList}
                        deleteFromList={deleteFromList}
                        handleSubmit={handleSubmit}
                        editFunction={editFunction}
                        cancelEdit={cancelEdit}
                        picklist={Ammatit}
                        permission={permission}
                      />
                    </td>
                  </tr>

                  <tr id="multi">
                    <td id="pointer">
                      <Typography variant="body1">
                        {t("rekry.tyokokeilu")}
                      </Typography>
                    </td>

                    <td className="editTd" id="Ammatit">
                      <Picklist
                        userData={rekryData}
                        field={"mahdTyokokeilu"}
                        AddToList={AddToList}
                        deleteFromList={deleteFromList}
                        handleSubmit={handleSubmit}
                        editFunction={editFunction}
                        cancelEdit={cancelEdit}
                        editSingleSelect={editSingleSelect}
                        picklist={Tyokokeilu}
                        permission={permission}
                      />
                    </td>
                  </tr>

                  <tr id="single">
                    <td id="pointer">
                      <Typography variant="body1">
                        {t("rekry.palkkaTuki")}
                      </Typography>
                    </td>

                    <td className="editTd" id="Ty_kokemusta_yhteens">
                      <Picklist
                        userData={rekryData}
                        field={"mahdPalkkatuki"}
                        AddToList={AddToList}
                        deleteFromList={deleteFromList}
                        handleSubmit={handleSubmit}
                        editFunction={editFunction}
                        cancelEdit={cancelEdit}
                        editSingleSelect={editSingleSelect}
                        picklist={Palkkatuki}
                        permission={permission}
                      />
                    </td>
                  </tr>

                  <tr id="single">
                    <td id="pointer">
                      <Typography variant="body1">
                        {t("rekry.vaihtoehdot")}
                      </Typography>
                    </td>

                    <td className="editTd" id="Ty_kokemusta_yhteens">
                      <Dropdown
                        userData={rekryData}
                        field={"vaihtoEhdot"}
                        AddToList={AddToList}
                        deleteFromList={deleteFromList}
                        handleSubmit={handleSubmit}
                        editFunction={editFunction}
                        cancelEdit={cancelEdit}
                        picklist={Vaihtoehdot}
                        permission={permission}
                      />
                    </td>
                  </tr>

                  <tr id="single">
                    <td id="pointer">
                      <Typography variant="body1">
                        {t("rekry.tyolupa")}
                      </Typography>
                    </td>

                    <td className="editTd" id="Ty_lupa_ja_pankkitili">
                      <Dropdown
                        userData={rekryData}
                        field={"tyoLupa"}
                        AddToList={AddToList}
                        deleteFromList={deleteFromList}
                        handleSubmit={handleSubmit}
                        editFunction={editFunction}
                        cancelEdit={cancelEdit}
                        picklist={Ty_lupa_ja_pankkitili}
                        permission={permission}
                      />
                    </td>
                  </tr>

                  <tr id="single">
                    <td id="pointer">
                      <Typography variant="body1">
                        {t("rekry.tyokokemus")}
                      </Typography>
                    </td>

                    <td className="editTd" id="Ty_kokemusta_yhteens">
                      <Picklist
                        userData={rekryData}
                        field={"experience"}
                        AddToList={AddToList}
                        deleteFromList={deleteFromList}
                        handleSubmit={handleSubmit}
                        editFunction={editFunction}
                        cancelEdit={cancelEdit}
                        editSingleSelect={editSingleSelect}
                        picklist={Ty_kokemusta_yhteens}
                        permission={permission}
                      />
                    </td>
                  </tr>

                  <tr id="multi">
                    <td id="pointer">
                      <Typography variant="body1">
                        {t("rekry.lupakortit")}
                      </Typography>
                    </td>

                    <td className="editTd" id="Lupakortit_ja_osaamispassit">
                      <Dropdown
                        userData={rekryData}
                        field={"passes"}
                        AddToList={AddToList}
                        deleteFromList={deleteFromList}
                        handleSubmit={handleSubmit}
                        editFunction={editFunction}
                        cancelEdit={cancelEdit}
                        picklist={Lupakortit_ja_osaamispassit}
                        permission={permission}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </Paper>
          </Grid>
        </Grid>

        <div
          className="save-btn-div"
          style={{ paddingBottom: "20px", width: "auto" }}
        >
          <div></div>
          <div className="middle">
            {loading ? (
              <LoadingSpinner type={"small"} />
            ) : (
              <>
                {modifiedData && (
                  <Button
                    className="save-button"
                    variant="contained"
                    style={{
                      background: "#4CAF50",
                      marginRight: "-40px",
                      marginTop: "20px",
                    }}
                    onClick={handleSubmit}
                  >
                    <Typography
                      sx={{ fontWeight: "bold", color: "#fefefe" }}
                      variant="body2"
                    >
                      {t("save")}
                    </Typography>
                  </Button>
                )}
              </>
            )}
          </div>
          <IconButton onClick={handleShowModal}>
            <SettingsIcon />
          </IconButton>
        </div>

        <Paper
          elevation={3}
          className="verticalBlock"
          id="workVerticalBlock"
          sx={{ width: "auto" }}
        >
          <div className="headerDiv">
            <Typography sx={{ marginBottom: "15px" }} variant="h4">
              {t("rekry.tyopaikat.title")}{" "}
            </Typography>

            <a className="header-info-link" onClick={() => setJobAdsShow(true)}>
              <InfoCircleFill color="white" size="20" />
            </a>
            {jobAdsShow ? (
              <DocsEmbed
                setOmatTiedotShow={setJobAdsShow}
                show={jobAdsShow}
                elementId={"_gxq444q6an8v"}
              />
            ) : (
              <></>
            )}
          </div>
          {!jobAds ? (
            <>
              <ul id="jobAdList" style={{ height: "100%" }}>
                <li className="jobAdItem">
                  {t("rekry.tyopaikat.tyopaikatErrorMsg")}
                </li>
              </ul>
            </>
          ) : (
            <>
              {jobAds.length > 0 ? (
                <>
                  <ul id="jobAdList">
                    {jobAds.map((singleAd, i) => (
                      <>
                        <li
                          onClick={() => toggleCollapse(i, singleAd)}
                          key={singleAd.id}
                          className="jobAdItem"
                        >
                          <div className="closedAdDiv">
                            <div className="jobTitles">
                              <img
                                className="plusAndMinus"
                                src={showCollapse[i] ? MinusBtn : PlusBtn}
                              />
                              <p className="jobAdTitle">{singleAd.adTitle}</p>
                            </div>

                            <a
                              href={singleAd.adUrl}
                              target="_blank"
                              onClick={() => handleJobAdLogoClick(singleAd)}
                              style={{ maxWidth: "4rem" }}
                            >
                              {singleAd.source === "Barona" ? (
                                <>
                                  <Tooltip
                                    theme="custom2"
                                    size="small"
                                    title={"Avaa alkuperäinen ilmoitus"}
                                    position="left"
                                    trigger="mouseenter"
                                  >
                                    <img
                                      className="jobAdLogo"
                                      src={BaronaLogo}
                                    />
                                  </Tooltip>
                                  <img className="linkIcon" src={linkIcon} />
                                </>
                              ) : singleAd.source === "Arffman" ? (
                                <>
                                  <Tooltip
                                    theme="custom2"
                                    size="small"
                                    title={"Avaa alkuperäinen ilmoitus"}
                                    position="left"
                                    trigger="mouseenter"
                                  >
                                    <img
                                      className="jobAdLogo"
                                      src={ArffmanLogo}
                                      style={{ backgroundColor: "white" }}
                                    />
                                  </Tooltip>
                                  <img className="linkIcon" src={linkIcon} />
                                </>
                              ) : singleAd.source === "Työmarkkinatori" ? (
                                <>
                                  <Tooltip
                                    theme="custom2"
                                    size="small"
                                    title={"Avaa alkuperäinen ilmoitus"}
                                    position="left"
                                    trigger="mouseenter"
                                  >
                                    <img
                                      className="jobAdLogo"
                                      src={BaronaLogo}
                                    />
                                  </Tooltip>
                                  <img className="linkIcon" src={linkIcon} />
                                </>
                              ) : (
                                <></>
                              )}
                            </a>
                          </div>
                          <Collapse in={showCollapse[i]}>
                            <div>
                              <div className="openAdDiv">
                                <div>
                                  <h5 className="jobAdh5">
                                    {t("rekry.tyopaikat.alueet")}
                                  </h5>
                                  <p className="jobAdParagraph">
                                    {singleAd.areas
                                      .toString()
                                      .replaceAll(",", ", ")}
                                  </p>
                                  <h5 className="jobAdh5">
                                    {t("rekry.tyopaikat.kaupungit")}
                                  </h5>
                                  <p className="jobAdParagraph">
                                    {singleAd.cities
                                      .toString()
                                      .replaceAll(",", ", ")}
                                  </p>
                                </div>
                                <div>
                                  <h5 className="jobAdh5">
                                    {t("rekry.tyopaikat.sopimus")}
                                  </h5>
                                  <p className="jobAdParagraph">
                                    {singleAd.contractType.toString()}
                                  </p>
                                  <h5 className="jobAdh5">
                                    {t("rekry.tyopaikat.luonne")}
                                  </h5>
                                  <p className="jobAdParagraph">
                                    {singleAd.workType.toString()}
                                  </p>
                                </div>
                                <div>
                                  <h5 className="jobAdh5">
                                    {t("rekry.tyopaikat.haku")}
                                  </h5>
                                  <p className="jobAdParagraph">
                                    {new Date(
                                      singleAd.endDate.toString()
                                    ).toLocaleDateString("fi")}
                                  </p>
                                  <h5 className="jobAdh5">
                                    {t("rekry.tyopaikat.yritys")}
                                  </h5>
                                  <p className="jobAdParagraph">
                                    {singleAd.company.toString()}
                                  </p>
                                </div>
                              </div>
                              <div className="jobDescDiv">
                                {singleAd.description}
                              </div>
                            </div>
                          </Collapse>
                        </li>
                      </>
                    ))}
                  </ul>
                </>
              ) : (
                <>
                  {jobAds.length === 0 ? (
                    <>-</>
                  ) : (
                    <>
                      <div className="jobAdsSpinner">
                        <LoadingSpinner type={"jobAds"} />
                      </div>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </Paper>
        <br></br>
        <Paper elevation={3} sx={{ padding: "20px" }}>
          <h2>
            {t("home.free.title")}{" "}
            <a href="https://free.fi" target="_blank" rel="noreferrer">
              Free.fi
            </a>{" "}
            {t("home.free.title2")}
          </h2>

          <p className="polkuText">
            {t("home.free.p1")}
            <a
              className="floatAnchor"
              style={{ color: "#0000FF" }}
              href="https://free.fi/yrittajyyskoulu/"
              target="_blank"
            >
              {t("home.free.p1a1")}
            </a>
            {t("home.free.p2")}
            <a
              className="floatAnchor"
              style={{ color: "#0000FF" }}
              href="https://free.fi/keikkapaikka/"
              target="_blank"
            >
              {t("home.free.p2a1")}
            </a>
            {t("home.free.p3")}
          </p>
        </Paper>
        <br></br>
      </Container>
    </>
  );
}

export default HakuProfiili;

/*

{(approvedWorkSearchs && approvedWorkSearchs.length) ||
        (notApprovedWorkSearchs && notApprovedWorkSearchs.length) ? (
          <>
            <div className="percentH2DivAlt">
              <h1 className="omatTiedotHeaderAlt">
                {t("rekry.newWorkSearchNotification")}
              </h1>
            </div>
            <div
              id="tableView"
              style={{
                marginBottom: "7dvh",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div>
                {notApprovedWorkSearchs.length ? (
                  <>
                    <Table>
                      <thead>
                        <th>{t("rekry.yrityksenNimi")}</th>
                        <th>{t("rekry.lupa")}</th>
                      </thead>
                      <tbody>
                        {notApprovedWorkSearchs.map((item, index) => (
                          <tr>
                            <td>{item.yritys}</td>
                            <td>
                              <label style={{ marginRight: "10px" }}>
                                {t("dataconsent.select2")}
                              </label>
                              <input
                                name={"permission" + index}
                                onClick={() =>
                                  setPermissions(item, "yes", index)
                                }
                                style={{ marginRight: "10px" }}
                                type="radio"
                              ></input>
                              <label style={{ marginRight: "10px" }}>
                                {t("dataconsent.select3")}
                              </label>
                              <input
                                name={"permission" + index}
                                onClick={() =>
                                  setPermissions(item, "no", index)
                                }
                                type="radio"
                              ></input>
                            </td>
                          </tr>
                        ))}
                        <tr>
                          {permissionLoading ? (
                            <>
                              <td colspan="2">
                                <button
                                  onClick={handlePermissionSubmit}
                                  className="button-81"
                                >
                                  <LoadingSpinner type="verySmall" />
                                </button>
                              </td>
                            </>
                          ) : (
                            <>
                              <td colspan="2">
                                <button
                                  onClick={handlePermissionSubmit}
                                  className="button-81"
                                >
                                  Tallenna
                                </button>
                              </td>
                            </>
                          )}
                        </tr>
                      </tbody>
                    </Table>
                  </>
                ) : (
                  <>
                    <p style={{ textAlign: "left" }}>Ei uusia pyyntöjä</p>
                  </>
                )}
              </div>

              {approvedWorkSearchs.length ? (
                <>
                  <div style={{ float: "left" }}>
                    {workSearchCollapse ? (
                      <>
                        <h6
                          style={{ cursor: "pointer", textAlign: "left" }}
                          onClick={() =>
                            setWorkSearchCollapse(!workSearchCollapse)
                          }
                        >
                          Vastatut <CaretDownFill />
                        </h6>
                      </>
                    ) : (
                      <>
                        <h6
                          style={{ cursor: "pointer", textAlign: "left" }}
                          onClick={() =>
                            setWorkSearchCollapse(!workSearchCollapse)
                          }
                        >
                          Vastatut <CaretRightFill />
                        </h6>
                      </>
                    )}
                  </div>
                </>
              ) : (
                <></>
              )}

              <div>
                <Collapse in={workSearchCollapse}>
                  <div>
                    <Table>
                      <thead>
                        <th>{t("rekry.yrityksenNimi")}</th>
                        <th>{t("rekry.lupa")}</th>
                      </thead>
                      <tbody>
                        {approvedWorkSearchs.map((item) => (
                          <tr>
                            <td>{item.yritys}</td>
                            <td>{item.lupa ? <>Kyllä</> : <>Ei</>}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </Collapse>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}

      
         
        </div>

        <div className="save-btn-div">
      <div></div> 
      <div className="middle">
        {loading ? (
          <LoadingSpinner type={"small"} />
        ) : (
          <>
            {modifiedData && (
              <Button
                className="save-button"
                variant="contained"
                style={{ background: "#4CAF50",marginRight:"-40px" }}
                onClick={handleSubmit}
              >
                <Typography sx={{ fontWeight: "bold" }} variant="body2">
                  {t("save")}
                </Typography>
              </Button>
            )}
          </>
        )}
      </div>
      <IconButton onClick={handleShowModal}>
        <SettingsIcon />
      </IconButton>
    </div>

   
*/
