import { React, useEffect, useState, useRef } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import AdbIcon from "@mui/icons-material/Adb";
import { useNavigate } from "react-router-dom";
import LanguageIcon from "@mui/icons-material/Language";

import { Link } from "react-router-dom";
import arffman from "../images/arffman.png";
import finImg from "../images/fin.png";
import sweImg from "../images/swe.png";
import engImg from "../images/eng.png";
import ukrImg from "../images/ukr.png";
import rusImg from "../images/rus.png";

const Navigation = ({ changeLanguage, handleLogOut, setInsModal }) => {
  const [refreshNav, setRefreshNav] = useState(true);
  const [admin, setAdmin] = useState(false);
  const [login, setLogin] = useState();

  const [userPhoto, setUserPhoto] = useState(null);
  const [fName, setFName] = useState("");
  const [permission, setPermission] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorLang, setAnchorLang] = useState(null);
  const [langMenu, setLangMenu] = useState(false);
  const [anchorLangNav, setAnchorLangNav] = useState(null);
  const navigate = useNavigate();

  const handleYourInfoClick = () => {
    handleClose();
    navigate("/omat-tiedot");
  };

  const handleFeedbackClick = () => {
    handleClose();
    navigate("/palaute");
  };

  const handleWhatsNewClick = () => {
    handleClose();
    navigate("/mita-uutta");
  };

  const handleFilesClick = () => {
    handleClose();
    navigate("/tiedostot");
  };
  const handleInformClick = () => {
    handleClose();
    navigate("/tyopaikka");
  };

  const handleFormClick = () => {
    handleClose();
    navigate("/lomakkeet");
  };

  const open = Boolean(anchorEl);
  const langOpen = Boolean(anchorLangNav);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenLangMenu = (event) => {
    setAnchorLangNav(event.currentTarget);
  };

  const handleCloseLangMenu = () => {
    setAnchorLangNav(null);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  /*
  <Typography
            variant="h5"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "black",
              textDecoration: "none",
            }}
          >
            <img id="arfLogo" src={arffman} alt="Arffman" />
          </Typography>
  */

  return (
    <AppBar position="sticky" sx={{ backgroundColor: "white", zIndex: 1000 }}>
      <Container
        maxWidth="xl"
        sx={{ maxWidth: "20000px!important", height: "4rem" }}
      >
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "black",
              textDecoration: "none",
            }}
          >
            <img id="arfLogo" src={arffman} alt="Arffman" />
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="black"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              <MenuItem
                onClick={handleCloseNavMenu}
                component={Link}
                to="palvelusi"
              >
                <Typography textAlign="center">Palvelusi</Typography>
              </MenuItem>
              <MenuItem
                onClick={handleCloseNavMenu}
                component={Link}
                to="hakuprofiili"
              >
                <Typography textAlign="center">Työnhaku</Typography>
              </MenuItem>
            </Menu>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {sessionStorage.getItem("goASifkFD4235") ? (
              <>
                <Typography
                  variant="subtitle1"
                  noWrap
                  component="a"
                  href="/palvelusi"
                  sx={{
                    mr: 2,
                    fontWeight: 400, // Making text bolder for a stronger appearance
                    color: "black", // Using a darker primary color for better contrast
                    textDecoration: "none",
                    textTransform: "none",
                    borderBottom: "2px solid transparent", // Adding a transparent border at bottom
                    transition: "color 0.3s, borderBottom 0.3s", // Smooth transition for color and borderBottom on hover
                    "&:hover": {
                      boxShadow: "20px",
                      color: "gray",
                      borderBottom: `2px solid secondary.main`, // Showing an underline on hover
                    },
                  }}
                >
                  Palvelusi
                </Typography>
                <Typography
                  variant="subtitle1"
                  noWrap
                  component="a"
                  href="/hakuprofiili"
                  sx={{
                    mr: 2,
                    fontWeight: 400, // Making text bolder for a stronger appearance
                    color: "black", // Using a darker primary color for better contrast
                    textDecoration: "none",
                    textTransform: "none",
                    borderBottom: "2px solid transparent", // Adding a transparent border at bottom
                    transition: "color 0.3s, borderBottom 0.3s", // Smooth transition for color and borderBottom on hover
                    "&:hover": {
                      boxShadow: "20px",
                      color: "gray",
                      borderBottom: `2px solid secondary.main`, // Showing an underline on hover
                    },
                  }}
                >
                  Työnhaku
                </Typography>
              </>
            ) : (
              <></>
            )}
          </Box>
          <Stack direction="row-reverse">
            {sessionStorage.getItem("goASifkFD4235") ? (
              <>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Tooltip title="Account settings">
                    <IconButton
                      onClick={handleClick}
                      size="small"
                      sx={{ ml: 2 }}
                      aria-controls={open ? "account-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                    >
                      <Avatar
                        sx={{ width: 32, height: 32 }}
                        src={sessionStorage.getItem("userPhoto")}
                      ></Avatar>
                    </IconButton>
                  </Tooltip>
                </Box>
                <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={open}
                  onClose={handleClose}
                  onClick={handleClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  <MenuItem onClick={handleYourInfoClick}>
                    <Avatar src={sessionStorage.getItem("userPhoto")} /> Omat
                    tiedot
                  </MenuItem>

                  <Divider />
                  <MenuItem onClick={handleFormClick}>Lomakkeet</MenuItem>
                  <MenuItem onClick={handleFeedbackClick}>
                    Anna palautetta
                  </MenuItem>

                  <MenuItem onClick={handleFilesClick}>Tiedostot</MenuItem>
                  <MenuItem onClick={handleInformClick}>
                    Ilmoita työllistymisestä
                  </MenuItem>
                  <MenuItem onClick={handleWhatsNewClick}>Mitä uutta</MenuItem>
                  <MenuItem onClick={handleLogOut}>
                    <ListItemIcon>
                      <Logout fontSize="small" />
                    </ListItemIcon>
                    Kirjaudu ulos
                  </MenuItem>
                </Menu>
              </>
            ) : (
              <>
                <div className="loginDiv" id="signinDiv"></div>
                
              </>
            )}
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={() => setInsModal(true)}
              color="black"
              sx={{ marginLeft: "0.5rem" }}
            >
              <QuestionMarkIcon />
            </IconButton>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenLangMenu}
              color="black"
            >
              <LanguageIcon />
            </IconButton>

            <Menu
              anchorEl={anchorLangNav}
              id="language select"
              open={langOpen}
              onClose={handleCloseLangMenu}
              onClick={handleCloseLangMenu}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 0,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem onClick={() => changeLanguage("fi")}>
                <Avatar src={finImg} />
              </MenuItem>
              <MenuItem onClick={() => changeLanguage("se")}>
                <Avatar src={sweImg} />
              </MenuItem>
              <MenuItem onClick={() => changeLanguage("en")}>
                <Avatar src={engImg} />
              </MenuItem>
              <MenuItem onClick={() => changeLanguage("ua")}>
                <Avatar src={ukrImg} />
              </MenuItem>
              <MenuItem onClick={() => changeLanguage("ru")}>
                <Avatar src={rusImg} />
              </MenuItem>
            </Menu>
          </Stack>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Navigation;
