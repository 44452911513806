import { React, useEffect, useState, useRef } from "react";
import Navigation from "./components/Navigation";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import WelcomePage from "./pages/WelcomePage";
import {
  Box,
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
  CircularProgress,
} from "@mui/material";
import { Modal, Button } from "react-bootstrap";
//import WelcomePageCopy from "./pages/WelcomePageCopy";
import OmatTiedot from "./pages/OmatTiedot";
import Home from "./pages/Home";
import { PrivateRoute } from "./PrivateRoute";
import HakuProfiili from "./pages/HakuProfiili";
import Palaute from "./pages/Palaute";
import Tiedostot from "./pages/Tiedostot";
import Forms from "./pages/Forms";
import WhatsNew from "./pages/WhatsNew";
import { useTranslation } from "react-i18next";
import "./i18n";
import ContractPage from "./pages/ContractPage";
import AcceptRequest from "./pages/AcceptRequest";
import Footer from "./components/Footer";
import { ToastContainer, toast } from "react-toastify";
import googleLoginImg from "./images/googleLoginImg.png";
import selectAccount from "./images/selectaccount_better.png";
import ohjeetFi from "./images/ohjeet_fi.png";
import ohjeetSe from "./images/ohjeet_se.png";
import ohjeetEn from "./images/ohjeet_en.png";
import ohjeetUa from "./images/ohjeet_ua.jpg";
import ohjeetRu from "./images/ohjeet_ru.png";

function App() {
  const [refreshNav, setRefreshNav] = useState(true);
  const [admin, setAdmin] = useState(false);
  const [login, setLogin] = useState();
  const [insModal, setInsModal] = useState(false);
  const [userPhoto, setUserPhoto] = useState(null);
  const [fName, setFName] = useState("");
  const [permission, setPermission] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    sessionStorage.setItem("lang", lng);
    i18n.changeLanguage(lng);
  };
  //

  let theme = createTheme({
    palette: {
      green: {
        main: "#4CAF50",
      },
      primary: {
        main: "#f08262",
      },
      secondary: {
        main: "#7db5da",
      },
    },
  });
  theme = responsiveFontSizes(theme);

  async function handleLogOut() {
    fetch("/api/user/logout", {
      method: "POST", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    }).then(function (response) {});
    setLogin(false);
    sessionStorage.clear();
  }

  useEffect(() => {
    setRefreshNav(!refreshNav);
  }, [login]);

  useEffect(() => {
    if (sessionStorage.getItem("lang")) {
      changeLanguage(sessionStorage.getItem("lang"));
    } else {
      changeLanguage("fi");
    }
  }, []);

  function handleLogin() {
    sessionStorage.setItem("goASifkFD4235", true);
    setLogin(true);
  }

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Navigation
          changeLanguage={changeLanguage}
          handleLogOut={handleLogOut}
          setInsModal={setInsModal}
        />
        <ToastContainer />
        <Modal
          size="lg"
          className="insModal"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={insModal}
          onHide={() => setInsModal(false)}
        >
          <Modal.Header style={{ alignSelf: "center" }} closeButton>
            <Modal.Title>
              <h3>{t("etusivuOhjeet.title")}</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="indexNoteOpen">
              <br />
              <iframe
                src={"https://docs.google.com/document/d/e/2PACX-1vRo_j1c-mpHDSfbZUwmbdn75Ed2NAwOGkxTGavp_mNlmaoEfhpmMFL4DHNeoUAOaUBRRPkEL3ejSLBH/pub?embedded=true"}
                style={{ border: "none", width: "100%", height: '70vh', padding: 'none' }} // CSS to remove the border
                title="Embedded Google Doc"
              >
                <CircularProgress />
              </iframe>

              <br />
              <Button id="loginIns" onClick={() => setInsModal(false)}>
                {t("etusivuOhjeet.close")}
              </Button>
            </div>
          </Modal.Body>
        </Modal>
        <Routes>
          <Route
            path="/"
            element={
              <Home
                handleLogin={handleLogin}
                setAdmin={setAdmin}
                login={login}
                setInsModal={setInsModal}
              />
            }
          />

          <Route
            path="palvelusi"
            element={
              <PrivateRoute login={login}>
                <WelcomePage
                  setUserPhoto={setUserPhoto}
                  setFName={setFName}
                  setPermission={setPermission}
                />{" "}
              </PrivateRoute>
            }
          />

          <Route
            path="betawp"
            element={
              <PrivateRoute login={login}>
                <WelcomePage
                  setUserPhoto={setUserPhoto}
                  setFName={setFName}
                  setPermission={setPermission}
                />{" "}
              </PrivateRoute>
            }
          />

          <Route
            path="omat-tiedot"
            element={
              <PrivateRoute login={login}>
                <OmatTiedot />{" "}
              </PrivateRoute>
            }
          />

          <Route
            path="hakuprofiili"
            element={
              <PrivateRoute login={login}>
                <HakuProfiili />{" "}
              </PrivateRoute>
            }
          />

          <Route
            path="palaute"
            element={
              <PrivateRoute login={login}>
                <Palaute />{" "}
              </PrivateRoute>
            }
          />

          <Route
            path="tiedostot"
            element={
              <PrivateRoute login={login}>
                <Tiedostot />{" "}
              </PrivateRoute>
            }
          />

          <Route
            path="lomakkeet"
            element={
              <PrivateRoute login={login}>
                <Forms />{" "}
              </PrivateRoute>
            }
          />

          <Route
            path="mita-uutta"
            element={
              <PrivateRoute login={login}>
                <WhatsNew />{" "}
              </PrivateRoute>
            }
          />

          <Route
            path="tyopaikka"
            element={
              <PrivateRoute login={login}>
                <ContractPage />{" "}
              </PrivateRoute>
            }
          />

          <Route path="acceptRequest" element={<AcceptRequest />} />

          <Route
            path="*"
            element={
              <main style={{ padding: "1rem" }}>
                <p>There's nothing here!</p>
              </main>
            }
          />
        </Routes>
        <Footer />
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;

/*

 <div className="App-body" id="Appbod">
      

        <footer>
          <div className="textFooter">
            <p>ARFFMAN</p>
            <a href="tel:044 733 1500">044 733 1500</a>
            <br />
            <a href="mailto:toimisto@edu.arffman.fi">toimisto@edu.arffman.fi</a>
            <br />
            <a target="_blank" href="https://www.arffman.fi/tietosuoja/">
              <span>{t("home.footer.p4")}</span>
            </a>
            <br />
          </div>

          <div className="someFooter">
            <a href="https://www.facebook.com/arffmanfinland/" target="_blank">
              <svg
                className="someSvg"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1000 1000"
              >
                <title>facebook</title>
                <path d="M0,0V1000H534.1V612.7H403.8V461.8H534.1V350.5c0-129.1,78.9-199.5,194.1-199.4,55.2,0,102.7,4.1,116.5,5.9V292H764.8c-62.7,0-74.8,29.8-74.8,73.5v96.4H839.5L820,612.8H690V1000h310V0Z"></path>
              </svg>
            </a>
            <a href="https://twitter.com/arffmanfinland" target="_blank">
              <svg
                className="someSvg"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1000 1000"
              >
                <title>twitter</title>
                <path d="M0,0V1000H1000V0ZM748.5,372.5c.4,5.5.4,11.1.4,16.6C748.9,558.6,619.9,754,384,754v-.1a363.16,363.16,0,0,1-196.5-57.5,257.22,257.22,0,0,0,189.8-53.1,128.52,128.52,0,0,1-119.8-89.1,127.34,127.34,0,0,0,57.9-2.2A128.17,128.17,0,0,1,212.5,426.3v-1.6a127.57,127.57,0,0,0,58.2,16A128.36,128.36,0,0,1,231,269.5a364.25,364.25,0,0,0,264.3,134,128.34,128.34,0,0,1,218.5-117,256.58,256.58,0,0,0,81.4-31.1,128.54,128.54,0,0,1-56.4,70.9,254.51,254.51,0,0,0,73.7-20.2A260.67,260.67,0,0,1,748.5,372.5Z"></path>
              </svg>
            </a>
            <a href="https://www.instagram.com/arffmanfinland/" target="_blank">
              <svg
                className="someSvg"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 28.87 28.87"
              >
                <title>instagram-svg</title>
                <g
                  id="2938a678-9746-4489-a5bb-29dbfb5d89bc"
                  data-name="instagram"
                >
                  <path
                    className="daa695c5-f96c-477a-ad38-2c5f0a8c24e3"
                    d="M18.77,14.44a4.34,4.34,0,1,1-8.54-1H8.82v5.92a.72.72,0,0,0,.72.72h9.79a.72.72,0,0,0,.72-.72V13.41H18.64A4.34,4.34,0,0,1,18.77,14.44Z"
                  ></path>
                  <rect
                    className="daa695c5-f96c-477a-ad38-2c5f0a8c24e3"
                    x="17.49"
                    y="8.88"
                    width="2.52"
                    height="2.52"
                    rx="0.4"
                    ry="0.4"
                  ></rect>
                  <path
                    className="daa695c5-f96c-477a-ad38-2c5f0a8c24e3"
                    d="M14.44,17.26a2.83,2.83,0,0,0,2.82-2.82,2.7,2.7,0,0,0-.2-1,2.81,2.81,0,0,0-5.25,0,2.7,2.7,0,0,0-.2,1A2.83,2.83,0,0,0,14.44,17.26Z"
                  ></path>
                  <path
                    className="daa695c5-f96c-477a-ad38-2c5f0a8c24e3"
                    d="M0,0V28.87H28.87V0ZM21.56,19.33a2.23,2.23,0,0,1-2.23,2.23H9.54a2.23,2.23,0,0,1-2.23-2.23V9.54A2.23,2.23,0,0,1,9.54,7.31h9.79a2.23,2.23,0,0,1,2.23,2.23Z"
                  ></path>
                </g>
              </svg>
            </a>
            <a href="https://www.linkedin.com/company/arffman/" target="_blank">
              <svg
                className="someSvg"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1000 1000"
              >
                <title>linkedin</title>
                <path
                  className="9c6bb508-5b07-4923-be81-8020f427c43d"
                  d="M0,0V1000H1000V0ZM296.59,852.13H148.26V374.91H296.59ZM222.46,309.68a86,86,0,1,1,86-86A86,86,0,0,1,222.46,309.68ZM852.11,852.13H703.9V620.05c0-55.36-1-126.54-77.08-126.54-77.18,0-89,60.29-89,122.54V852.13H389.61V374.91H531.89v65.2h2C553.65,402.59,602,363,674.19,363c150.19,0,177.92,98.84,177.92,227.33Z"
                ></path>
              </svg>
            </a>
          </div>
        </footer>
      </div>
*/
