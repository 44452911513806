import React, { useState, useEffect } from "react";
import Settings from "../images/settings.png";
import Textbox from "../components/Textbox";
import { GearFill } from "react-bootstrap-icons";
import LoadingSpinner from "../components/Misc/LoadingSpinner";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DataConsent from "../components/DataConsent";
import DocsEmbed from "../components/GoogleDocs/DocsEmbed";
import { InfoCircleFill } from "react-bootstrap-icons";
import NoUser from "../images/user.png";
import PhotoAdd from "../images/add-photo.png";
import AddPhoto from "../components/AddPhoto";
import { useTranslation } from "react-i18next";
import "../i18n";
import NameChange from "../components/NameChange";

function OmatTiedot() {
  const [userData, setUserData] = useState();
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [loading, setLoading] = useState(null);
  const [modifiedData, setModifiedData] = useState(null);
  const [originalData, setOriginalData] = useState(null);
  const [editCount, setEditCount] = useState(0);
  const [omatTiedotShow, setOmatTiedotShow] = useState(false);
  const [userPhoto, setUserPhoto] = useState(null);
  const [photoHover, setPhotoHover] = useState(false);
  const [photoModal, setPhotoModal] = useState(false);
  const [continueNameChange, setContinueNameChange] = useState(false);
  const [hasImg, setHasImg] = useState(null);
  const [permission, setPermission] = useState(
    sessionStorage.getItem("permission")
  );

  const { t, i18n } = useTranslation();

  const notifySuccess = () =>
    toast.success("Tiedot tallennettu!", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const notifyError = () =>
    toast.error(
      "Virhe: Yritä uudelleen tai ota yhteyttä palveluasiantuntijaasi.",
      {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );

  const notifyNoPermitSet = () =>
    toast.error(
      "Et ole asettanut vielä tietojenkäsittelylupaasi. Paina ruudun alareunassa olevaa Asetukset nappulaa asettaaksesi tietojenkäsittelyluvan.",
      {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );

  async function getUserData(action) {
    let parsedData;

    if (sessionStorage.getItem("userData") && action !== "reFetch") {
      parsedData = sessionStorage.getItem("userData");
      setUserDataFunction(parsedData);
    } else {
      await fetch("/api/user/userdata/" + sessionStorage.getItem("id") + "/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then(function (response) {
          //console.log(response)
          if (response.ok) {
            return response.json();
          }
        })
        .then(async function (data) {
          setUserDataFunction(data, action);
        })
        .catch((error) => {
          console.log("error: " + error);
        });
    }
  }

  async function setUserDataFunction(data, action) {
    let parsedData = JSON.parse(data);

    if (parsedData.photo === "ok") {
      const response = await fetch(
        "/api/photo/getuserphoto/" + sessionStorage.getItem("id") + "/",
        {
          method: "GET",
        }
      )
        .then(function (response) {
          //console.log(response)
          if (response.ok) {
            return response.blob();
          }
        })
        .then((myBlob) => {
          const objectURL = URL.createObjectURL(myBlob);
          sessionStorage.setItem("userPhoto", objectURL);
          setHasImg(true);
          setUserPhoto(objectURL);
        });
    } else {
      sessionStorage.setItem("userPhoto", NoUser);
      setHasImg(false);
      setUserPhoto(NoUser);
    }

    //console.log("ajetaan?")
    if (!parsedData.dataPermit.value) notifyNoPermitSet();
    setUserData(parsedData);
    setOriginalData(JSON.stringify(parsedData));
    sessionStorage.setItem("name", parsedData.recordFirstName.value);
    sessionStorage.setItem("userData", data);
    let counter = 0;

    let fillableData = [
      "recordCity",
      "recordEmail",
      "recordFirstName",
      "recordLanguage",
      "recordLastName",
      "recordOtherLang",
      "recordStreet",
      "recordZip",
    ];

    for (const item in parsedData) {
      if (parsedData[item].value) {
        //console.log("asd", parsedData[item])
        if (fillableData.includes(item.toString())) {
          counter++;
        }
      }
    }
    let userCounter = counter;
    let userLength = 8;

    let fillPercent = Math.round((userCounter / userLength) * 100);

    let percentage = (counter) => {
      let progress = document.getElementById("userProgressPage");
      let progressValue = document.getElementById("user-progress-value");
      //let scrollValue = 50;
      progress.style.background = `conic-gradient(#008fff ${counter}%, #c0c0ff ${counter}%)`;
      progressValue.textContent = counter + "%";
    };
    percentage(fillPercent);
    if (action === "reFetch") notifySuccess();
    setLoading(false);
  }

  function handleSubmit(value) {
    // console.log("eikö lähe")
    console.log("value", value);
    if (
      (!value && userData["recordFirstName"].checked) ||
      (!value && userData["recordLastName"].checked)
    ) {
      handleShowModal2();
    } else {
      setLoading(true);
      const response = fetch(
        "/api/user/submituserdata/" + sessionStorage.getItem("id"),
        {
          method: "POST",
          body: JSON.stringify(userData),
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          //console.log(data);
          if (data.toString() === "ok") {
            getUserData("reFetch");
          }

          setModifiedData(false);
          setContinueNameChange(false);
          setEditCount(0);
        })
        .catch((error) => {
          console.log("error: " + error);
          setLoading(false);
          notifyError();
          setEditCount(0);
          setContinueNameChange(false);
        });
    }
  }

  function test(value) {
    const response = fetch("/api/reqsession/" + sessionStorage.getItem("id"), {
      method: "POST",
      body: JSON.stringify(userData),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        //console.log(data);
      })
      .catch((error) => {
        console.log("error: " + error);
      });
  }

  //console.log("test", userData)

  function editFunction(choice) {
    if (!loading) setModifiedData(true);

    //console.log("wqoeeqwowpq", userData.$(choice.objName))
    setEditCount(editCount + 1);
    setUserData((userData) => {
      return { ...userData, [choice.objName]: { ...choice, checked: true } };
    });
  }

  function cancelEdit(choice) {
    setEditCount(editCount - 1);
    let orig = JSON.parse(originalData);
    let compare = orig;
    orig = orig[choice.objName].value;
    setUserData((userData) => {
      return {
        ...userData,
        [choice.objName]: { ...choice, checked: false, value: orig },
      };
    });
    if (editCount === 1) setModifiedData(false);
  }

  function editValue(choice, e) {
    setUserData((userData) => {
      return { ...userData, [choice.objName]: { ...choice, value: e } };
    });
  }

  if (userData) {
    // console.log("fields", userData)
  }

  function deleteFromList(e, choice) {
    let newList = userData[choice].value;
    userData[choice].value.forEach((element, index) => {
      if (element === e) {
        newList.splice(index, 1);
      }
      setUserData((userData) => {
        return { ...userData, [choice.objName]: { ...choice, value: newList } };
      });
    });
  }

  function AddToList(e, choice) {
    //console.log("e", e, "choice", choice)
    let newList;
    if (userData[choice.objName].value) {
      newList = userData[choice.objName].value;
    } else {
      newList = [];
    }
    let check = false;
    if (newList.length > 0) {
      newList.forEach((element) => {
        if (element === e) {
          check = true;
        }
      });
    }

    if (!check) {
      newList.push(e);
      //console.log("newList", newList)
      setUserData((userData) => {
        return { ...userData, [choice.objName]: { ...choice, value: newList } };
      });
    }
  }

  function editSingleSelect(e, choice) {
    //console.log("e", e, "choice", choice)
    setUserData((userData) => {
      return { ...userData, [choice.objName]: { ...choice, value: e } };
    });
  }
  function handleShowModal() {
    setShow(true);
  }

  function handleCloseModal() {
    setShow(false);
  }

  function handleShowModal2() {
    setShow2(true);
  }

  function handleCloseModal2() {
    setShow2(false);
  }

  function handleShowAddPhoto() {
    setPhotoModal(true);
  }

  function handleCloseAddPhoto() {
    setPhotoModal(false);
  }

  useEffect(() => {
    getUserData();
  }, []);
  //console.log("show", show)

  /*
  {omatTiedotShow ? (
          <DocsEmbed
            setOmatTiedotShow={setOmatTiedotShow}
            show={omatTiedotShow}
            elementId={"_tli8haywgedf"}
          />
        ) : (
          <></>
        )}

         <div
                  style={{
                    placeSelf: "center",
                    paddingLeft: "6px",
                    paddingBottom: "-1px",
                  }}
                >
                  <a
                    className="header-info-link"
                    onClick={() => setOmatTiedotShow(true)}
                  >
                    <InfoCircleFill color="black" size="18" />
                  </a>
                </div>
  */
  return (
    <>
      <div style={{ paddingTop: "0vh" }} id="tableContainerRekry">
      

        <div id="tableView-OmatTiedot">
          <div className="omatTiedot-emptyHead">
            <img
              className="userPhoto"
              src={photoHover ? PhotoAdd : userPhoto}
              onMouseEnter={() => setPhotoHover(true)}
              onMouseLeave={() => setPhotoHover(false)}
              onClick={() => handleShowAddPhoto()}
            />
            <div className="omatTiedot-percentH2Div">
              <div id="userProgressPage" className="progressBall">
                <span
                  className="progress-value"
                  id="user-progress-value"
                ></span>
              </div>
              <div className="info-btn">
                <div>
                  {userData ? (
                    <>
                      <h3 className="omatTiedotHeader">
                        {userData.recordFirstName.value}
                      </h3>
                    </>
                  ) : (
                    <></>
                  )}
                </div>

               
              </div>{" "}
              {omatTiedotShow ? (
                <DocsEmbed
                  setOmatTiedotShow={setOmatTiedotShow}
                  show={omatTiedotShow}
                  elementId={"_tli8haywgedf"}
                />
              ) : (
                <></>
              )}
              <div id="emptyHeadDiv"></div>
            </div>

            <div className="omatTiedotData-container">
              <table id="personalData3" className="content-table">
                <thead>
                  <tr></tr>
                </thead>
                <tbody>
                  <DataConsent
                    show={show}
                    handleCloseModal={handleCloseModal}
                    notifyError={notifyError}
                    notifySuccess={notifySuccess}
                  />

                  <NameChange
                    show={show2}
                    handleCloseModal={handleCloseModal2}
                    setContinueNameChange={setContinueNameChange}
                    notifyError={notifyError}
                    notifySuccess={notifySuccess}
                    handleSubmit={handleSubmit}
                  />

                  <tr id="textType">
                    <td id="pointer">{t("omat-tiedot.firstName")}</td>
                    <td className="editTd" id="First_Name">
                      <Textbox
                        userData={userData}
                        field={"recordFirstName"}
                        handleSubmit={handleSubmit}
                        editFunction={editFunction}
                        cancelEdit={cancelEdit}
                        editValue={editValue}
                        permission={permission}
                      />
                    </td>
                  </tr>

                  <tr id="textType">
                    <td id="pointer">{t("omat-tiedot.lastName")}</td>
                    <td className="editTd" id="Last_Name">
                      <Textbox
                        userData={userData}
                        field={"recordLastName"}
                        handleSubmit={handleSubmit}
                        editFunction={editFunction}
                        cancelEdit={cancelEdit}
                        editValue={editValue}
                        permission={permission}
                      />
                    </td>
                  </tr>

                  <tr id="textType">
                    <td id="pointer">{t("omat-tiedot.email")}</td>
                    <td className="editTd" id="Email">
                      <Textbox
                        userData={userData}
                        field={"recordEmail"}
                        handleSubmit={handleSubmit}
                        editFunction={editFunction}
                        cancelEdit={cancelEdit}
                        editValue={editValue}
                        permission={permission}
                      />
                    </td>
                  </tr>

                  <tr id="textType">
                    <td id="pointer">{t("omat-tiedot.phone")}</td>
                    <td className="editTd" id="Email">
                      <Textbox
                        userData={userData}
                        field={"recordPhone"}
                        handleSubmit={handleSubmit}
                        editFunction={editFunction}
                        cancelEdit={cancelEdit}
                        editValue={editValue}
                        permission={permission}
                      />
                    </td>
                  </tr>

                  <tr id="textType">
                    <td id="pointer">{t("omat-tiedot.street")}</td>

                    <td className="editTd" id="Mailing_Street">
                      <Textbox
                        userData={userData}
                        field={"recordStreet"}
                        handleSubmit={handleSubmit}
                        editFunction={editFunction}
                        cancelEdit={cancelEdit}
                        editValue={editValue}
                        permission={permission}
                      />
                    </td>
                  </tr>

                  <tr id="textType">
                    <td id="pointer">{t("omat-tiedot.zip")}</td>

                    <td className="editTd" id="Mailing_Zip">
                      <Textbox
                        userData={userData}
                        field={"recordZip"}
                        handleSubmit={handleSubmit}
                        editFunction={editFunction}
                        cancelEdit={cancelEdit}
                        editValue={editValue}
                        permission={permission}
                      />
                    </td>
                  </tr>

                  <tr id="textType">
                    <td id="pointer">{t("omat-tiedot.city")}</td>

                    <td className="editTd" id="Mailing_City">
                      <Textbox
                        userData={userData}
                        field={"recordCity"}
                        handleSubmit={handleSubmit}
                        editFunction={editFunction}
                        cancelEdit={cancelEdit}
                        editValue={editValue}
                        permission={permission}
                      />
                    </td>
                  </tr>

                  <tr id="textType">
                    <td id="pointer">{t("omat-tiedot.language")}</td>

                    <td className="editTd" id="idinkieli">
                      <Textbox
                        userData={userData}
                        field={"recordLanguage"}
                        handleSubmit={handleSubmit}
                        editFunction={editFunction}
                        cancelEdit={cancelEdit}
                        editValue={editValue}
                        permission={permission}
                      />
                    </td>
                  </tr>

                  <tr id="textType">
                    <td id="pointer">{t("omat-tiedot.otherLanguages")}</td>

                    <td className="editTd" id="Muu_kielitaito">
                      <Textbox
                        userData={userData}
                        field={"recordOtherLang"}
                        handleSubmit={handleSubmit}
                        editFunction={editFunction}
                        cancelEdit={cancelEdit}
                        editValue={editValue}
                        permission={permission}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="omatTiedot-container">
              <div className="emptyDiv"></div>
              <div id="rekryBtnDiv">
                {loading ? (
                  <>
                    <LoadingSpinner type={"small"} />
                  </>
                ) : (
                  <>
                    {modifiedData ? (
                      <>
                        <button
                          className="saveData-button"
                          onClick={() => handleSubmit(false)}
                        >
                          {t("save")}
                        </button>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </div>

              <div id="rekryBtnDiv" style={{ marginTop: "20px" }}>
                <AddPhoto
                  show={photoModal}
                  handleCloseModal={handleCloseAddPhoto}
                  getUserData={getUserData}
                  hasImg={hasImg}
                />
                <button
                  id="settingButton"
                  className="Asetukset-button"
                  onClick={handleShowModal}
                  style={{ alignItems: "center", gap: "3px" }}
                >
                  <GearFill />
                  {t("settings")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br></br>
    </>
  );
}

export default OmatTiedot;
