import React, { useState, useCallback } from "react";
import { Modal, Button } from "react-bootstrap";
import LoadingSpinner from "../components/Misc/LoadingSpinner";
import Dropzone from "../components/FileAdd/DropZone";
import { toast } from "react-toastify";

function AddPhoto({ show, handleCloseModal, getUserData, hasImg }) {
  const [value, setValue] = useState(null);
  const [pickedFile, setPickedFile] = useState();
  const [imgUrl, setImgUrl] = useState(null);
  const [saveLoading, setSaveLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.map((file) => {
      const reader = new FileReader();

      let imgSrc = URL.createObjectURL(file);
      console.log("filu", imgSrc);
      setImgUrl(imgSrc);

      setPickedFile(file);
      return file;
    });
  }, []);

  const notifyError = (msg) =>
    toast.error(msg, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const notifySuccess = (msg) =>
    toast.success(msg, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  async function handlePhotoDelete() {
    setDeleteLoading(true);
    const response = fetch("/api/photo/deleteuserphoto/" + sessionStorage.getItem("id"), {
      method: "POST",
    })

      .then(function (response) {
        //console.log(response)
        if (response.ok) {
          return response.json();
        }
      })
      .then(async function (data) {
        console.log(data);
        getUserData("reFetch");
        setPickedFile("");
        setImgUrl(null)
        handleCloseModal();
        notifySuccess("Profiilikuvasi poistettiin");
        setDeleteLoading(false);
      })
      .catch((error) => {
        console.log("error: " + error);
        notifyError(
          "Jotain meni vikaan. Yritä hetken kuluttua uudelleen ja ota tarvittaessa yhteys kouluttajaasi/valmentajaasi"
        );
        setImgUrl(null)
        setPickedFile("");
        handleCloseModal();
        setDeleteLoading(false);
      });
  }

  function handleHide() {
    setPickedFile("");
    setImgUrl(null)
    handleCloseModal();
  }

  function handleSubmit(blobFile) {
    if (!blobFile) {
      notifyError("Valitse kuvatiedosto ennen tallentamista.");
    } else {
      setSaveLoading(true);

      const formData = new FormData();
      formData.append("file", blobFile);

      const response = fetch("/api/photo/adduserphoto/" + sessionStorage.getItem("id"), {
        method: "POST",
        body: formData,
        headers: {},
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          console.log(data);
          if (data.success === "ok") {
            setPickedFile("");
            handleCloseModal();
            setImgUrl(null)
            getUserData("reFetch");
            notifySuccess("Profiilikuva tallennettu!");
            setSaveLoading(false);
          } else {
            notifyError(
              "Jotain meni vikaan. Yritä hetken kuluttua uudelleen ja ota tarvittaessa yhteys kouluttajaasi/valmentajaasi"
            );
            handleCloseModal();
            setPickedFile("");
            setImgUrl(null)
            setSaveLoading(false);
          }
        })
        .catch((error) => {
          console.log("error: " + error);
        });
    }
  }

  return (
    <Modal
      size="lg"
      className="addPhotoModal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={handleHide}
    >
      <Modal.Header style={{ alignSelf: "center" }}>
        <Modal.Title>
          <h3>Lisää profiilikuva</h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="imgDropZone">
        
            <Dropzone maxFiles={1} onDrop={onDrop} img={imgUrl} setPickedFile={setPickedFile} handleSubmit={handleSubmit}/>
        </div>
        <div style={{ textAlign: "center" }}>
          {hasImg && !imgUrl ? (
            <>
              <Button
                onClick={() => handlePhotoDelete()}
                style={{
                  backgroundColor: "#F08262",
                  color: "black",
                  border: "none",
                }}
              >
                {deleteLoading ? (
                  <LoadingSpinner type={"small"} />
                ) : (
                  "Poista profiilikuva"
                )}
              </Button>
            </>
          ) : (
            <></>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default AddPhoto;
