
import React from 'react'
import { InfoCircleFill, XCircle, CaretDownFill, CaretRightFill, CupStraw } from 'react-bootstrap-icons';
import { Collapse, Table } from "react-bootstrap";
import SingleCollapse from './SingleCollapse';
import { versionHistory } from './VersionHistory';

export const version = () => {
  let number = 0;
for(let i = 0; versionHistory.length > i; i++){
  if(versionHistory[i].versio > number) number = versionHistory[i].versio 
}

return number
}

function WhatsNew({ whatsNew, setWhatsNew, open, setOpen, version }) {

  console.log(versionHistory)
  return (
    <>
    {whatsNew ? (
        <div id="whatsNewNotify">
          {open ? (
            <>
              <h5
                onClick={() => setOpen(!open)}
                style={{
                  cursor: "pointer",
                  borderBottom: "solid thin #7db5da",
                  width: "85%",
                }}
              >
                Portaali on päivittynyt edellisen kirjautumisesi jälkeen!
                <CaretDownFill
                  style={{
                    color: "rgb(143, 143, 143)",
                    marginBottom: "0.2vh",
                    marginLeft: "0.5vw",
                  }}
                />
                <XCircle
                  id="circleFill"
                  onClick={() => setWhatsNew(false)}
                />
              </h5>
              <Collapse in={open}>
                <Table className="whatsNewTable" style={{ width: "85%" }}>
                  <thead></thead>
                  <tbody>
                    {versionHistory.map((item) => (
                      <SingleCollapse
                      header={item.header}
                      text={item.text}
                      pvm={item.pvm}
                      versio={item.versio}
                      />
                    ))}
                 
      
                  </tbody>
                </Table>
              </Collapse>
            </>
          ) : (
            <h5
              onClick={() => setOpen(!open)}
              style={{
                cursor: "pointer",
                borderBottom: "solid thin #7db5da",
                width: "85%",
              }}
            >
              Portaali on päivittynyt edellisen kirjautumisesi jälkeen!
              <CaretRightFill
                style={{
                  color: "rgb(143, 143, 143)",
                  marginBottom: "0.2vh",
                  marginLeft: "0.5vw",
                }}
              />
              <XCircle id="circleFill" onClick={() => setWhatsNew(false)} />
            </h5>
          )}
        </div>
      ) : (
        <></>
      )}
      </>
  )
}

export default WhatsNew
