import React, { useState, useEffect } from "react";
import NoUser from "../images/user.png";
import { useNavigate } from "react-router-dom";
import gmailIcon from "../images/gmail_icon.png";
import driveIcon from "../images/drive_icon.png";
import LoadingSpinner from "../components/Misc/LoadingSpinner";
import { useTranslation } from "react-i18next";
import CustomProjectView from "../components/CustomProjects";
import { InfoCircleFill, ArrowClockwise } from "react-bootstrap-icons";
import WhatsNew from "../components/WhatsNew/WhatsNew";
import { version } from "../components/WhatsNew/WhatsNew";
import ProgressBar from "react-bootstrap/ProgressBar";
import { Tooltip } from "react-tippy";
import SingleWebinarMonth from "../components/SingleWebinarMonth";
import Container from "@mui/material/Container";
import { Paper, Typography } from "@mui/material";
import { Grid, Divider } from "@mui/material";
import { Box } from "@mui/material";
import WebMaterialCard from "./WebMaterialCard";
import ServiceInformationGrid from "./ServiceInformationGrid";
import CircularProgress from "@mui/material/CircularProgress";
import SingleForm from "../components/Forms/SingleForm";
import { Modal } from "react-bootstrap";
import { useTheme } from "@mui/material/styles";
//
function WelcomePage({ setUserPhoto, setFName, setPermission }) {
  const navigate = useNavigate();

  const [userData, setUserData] = useState();
  const [rekryData, setRekryData] = useState();
  const [refresh, setRefresh] = useState("");
  const [returnLink, setReturnLink] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [omatTiedotShow, setOmatTiedotShow] = useState(false);
  const [allProjects, setAllProjects] = useState(null);
  const [whatsNewState, setWhatsNewState] = useState();
  const [open, setOpen] = useState(false);
  const [progress, setProgress] = useState(0);
  const [guessedEndDate, setGuessedEndDate] = useState(false);
  const [startEndTitle, setStartEndTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const [projectLinks, setProjectLinks] = useState([]);
  const [serviceLinks, setServiceLinks] = useState([]);
  const [openCollapse, setOpenCollapse] = useState(false);
  const [webMaterials, setWebMaterials] = useState(null);
  const [customLoading, setCustomLoading] = useState(false);
  const [webMloading, setWebMloading] = useState(false);
  const { t, i18n } = useTranslation();
  const [webinars, setWebinars] = useState(null);
  const [showIframe, setShowIframe] = useState(false);
  const [iframeUrl, setIframeUrl] = useState("");
  const [showProjectSelection, setShowProjectSelection] = useState(false);
  const [projectForms, setProjectForms] = useState([]);
  const [forceAnswers, setForceAnswers] = useState();
  const [showForce, setShowForce] = useState(false);
  const [assessments, setAssessments] = useState(null);

  const theme = useTheme();

  const containerStyle = {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {},
    [theme.breakpoints.up("lg")]: {},
  };

  const changeLanguage = (lng) => {
    sessionStorage.setItem("lang", lng);
    i18n.changeLanguage(lng);
  };

  const getForms = () => {
    setLoading(true);

    let obj = {
      projectId: JSON.parse(sessionStorage.getItem("userData")).projectId.value,
      userId: JSON.parse(sessionStorage.getItem("userData")).searchRecId.value,
    };
    const response = fetch(
      "/api/user/getForms/" + sessionStorage.getItem("id"),
      {
        method: "POST",
        body: JSON.stringify(obj),
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        let forceArr = [];
        for (let i = 0; i < data.projectForms.length; i++) {
          let form = data.projectForms[i];

          if (form.forceAnswer && !form.answers) {
            forceArr.push(form);
          }
        }
        if (forceArr.length > 0) {
          setShowForce(true);
        } else {
          setShowForce(false);
        }
        //console.log(data)
        setForceAnswers(forceArr);

        console.log(data.projectForms);
        if (!data.projectForms.error) {
          setProjectForms(data.projectForms);
        }

        setLoading(false);
      })
      .catch((error) => {
        console.log("error: " + error);
        setLoading(false);
      });
  };

  //<button id="showReturn">Palauta laite</button>
  //console.log(userData ? userData : "none")
  async function getUserData(action) {
    let parsedData;
    let versionNumber = version();
    let obj = { stamp: true, version: versionNumber };
    if (sessionStorage.getItem("userData") && action !== "reFetch") {
      parsedData = sessionStorage.getItem("userData");
      setUserDataFunction(parsedData);
      getForms();
    } else {
      const response = await fetch(
        "/api/user/userdata/" + sessionStorage.getItem("id") + "/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(obj),
        }
      )
        .then(function (response) {
          //console.log(response)
          if (response.ok) {
            return response.json();
          }
        })
        .then(async function (data) {
          sessionStorage.setItem(
            "permission",
            JSON.parse(data).usePermission.value
          );
          setPermission(JSON.parse(data).usePermission.value);
          setUserDataFunction(data);
          getForms();
          //getAssessments(JSON.parse(data).searchRecId.value, JSON.parse(data).projectId.value);
        })
        .catch((error) => {
          console.log("error: " + error);
        });
    }
  }

  async function getWebinars() {
    const response = await fetch(
      "/api/misc/webinars/" + sessionStorage.getItem("id") + "/",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then(function (response) {
        //console.log(response)
        if (response.ok) {
          return response.json();
        }
      })
      .then(async function (data) {
        function sortObjectByStartDate(obj) {
          // Convert the object into an array of key-value pairs
          const entries = Object.entries(obj);

          // Get the current date
          const currentDate = new Date();

          // Sort the array based on the proximity of the first startDate to the current date
          entries.sort(([monthA, dataA], [monthB, dataB]) => {
            const startDateA = new Date(dataA[0].startDate);
            const startDateB = new Date(dataB[0].startDate);

            // Calculate the difference in milliseconds
            const diffA = Math.abs(startDateA - currentDate);
            const diffB = Math.abs(startDateB - currentDate);

            return diffA - diffB;
          });

          // Convert the sorted array back to an object
          const sortedObject = Object.fromEntries(entries);

          return sortedObject;
        }

        const sortedArrays = sortObjectByStartDate(data);
        setWebinars(sortedArrays);
      })
      .catch((error) => {
        console.log("error: " + error);
      });
  }

  const InfoBox = () => {
    return (
      <div className="popup-box2" style={{ color: "black" }}>
        <div className="box2" style={{ background: "white" }}>
          <span className="close-icon" onClick={() => setIsOpen(false)}>
            x
          </span>
          <p style={{ fontSize: "1.25rem" }}>
            Henkilöstön testikäyttäjä on kaikkien Arffmanin työntekijöiden
            näkemä kuvitteellinen palvelussamme oleva asiakas. Voit katsella ja
            muokata hänen työnhakuprofiilia sekä omia tietoja, mutta huomaa,
            että muokkaukset näkyvät kaikille työntekijöillemme.
            <br></br> <br></br>
            Palvelusi -sivulla voit myös vaihtaa projektia, jossa testikäyttäjä
            on osallistujana. Tämä vaihdos näkyy vain sinulle eikä tallennu.
            Voit näin esikatsella miltä oman projektisi tiedot näyttävät omille
            asiakkaillesi.
          </p>
        </div>
      </div>
    );
  };

  async function setUserDataFunction(data) {
    let parsedData = JSON.parse(data);
    if (parsedData.photo === "ok") {
      const response = await fetch(
        "/api/photo/getuserphoto/" + sessionStorage.getItem("id") + "/",
        {
          method: "GET",
        }
      )
        .then(function (response) {
          //console.log(response)
          if (response.ok) {
            return response.blob();
          }
        })
        .then((myBlob) => {
          const objectURL = URL.createObjectURL(myBlob);
          sessionStorage.setItem("userPhoto", objectURL);
          setUserPhoto(objectURL);
        });
    } else {
      sessionStorage.setItem("userPhoto", NoUser);
      setUserPhoto(NoUser);
    }

    sessionStorage.setItem("name", parsedData.recordFirstName.value);
    setFName(parsedData.recordFirstName.value);
    setProjectLinks(parsedData.projectLinks);
    setServiceLinks(parsedData.serviceLinks);
    setUserData(parsedData);

    sessionStorage.setItem("forms", JSON.stringify(parsedData.projectForms));
    sessionStorage.setItem("userData", data);
    if (parsedData.teachingLanguage.value === "englanti") changeLanguage("en");
    else if (parsedData.teachingLanguage.value === "ruotsi")
      changeLanguage("se");
    else if (parsedData.teachingLanguage.value === "ukraina")
      changeLanguage("ua");
    else if (parsedData.teachingLanguage.value === "venäjä")
      changeLanguage("ru");
    if (!parsedData.version.value) sessionStorage.setItem("version", "0");
    else sessionStorage.setItem("version", parsedData.version.value);
    if (parsedData.version.value < version() && !version)
      setWhatsNewState(true);

    if (parsedData.startDate.value) {
      ProgressCalculator(
        parsedData.startDate.value,
        parsedData.projectType.value,
        parsedData.projectEndDate.value,
        "Aloituspäiväsi on "
      );
    } else if (
      !parsedData.startDate.value &&
      parsedData.projectStartDate.value
    ) {
      ProgressCalculator(
        parsedData.projectStartDate.value,
        parsedData.projectType.value,
        parsedData.projectEndDate.value,
        "Palvelun aloituspäivä on "
      );
    } else if (
      !parsedData.startDate.value &&
      !parsedData.projectStartDate.value
    ) {
      const today = new Date(); // Get the current date
      const lastYear = today.getFullYear() - 1; // Get the year of last year
      const janFirstLastYear = new Date(lastYear, 1, -29); // Create a new Date object for January 1st of last year

      ProgressCalculator(
        janFirstLastYear.toISOString().split("T")[0],
        parsedData.projectType.value,
        parsedData.projectEndDate.value,
        "Tarkka aloituspäivä puuttuu. Päiväksi asetettiin "
      );
    }

    let counter = 0;

    let fillableData = [
      "eduField",
      "eduLevel",
      "education",
      "experience",
      "fields",
      "passes",
      "professions",
      "recordCity",
      "recordEmail",
      "recordFirstName",
      "recordLanguage",
      "recordLastName",
      "recordOtherLang",
      "recordStreet",
      "recordZip",
    ];

    for (const item in parsedData) {
      if (parsedData[item].value) {
        //console.log("asd", parsedData[item])
        if (fillableData.includes(item.toString())) {
          counter++;
        }
      }
    }
    let userCounter = counter;
    let userLength = 15;

    let fillPercent = Math.round((userCounter / userLength) * 100);

    let percentage = (counter) => {
      let progress = document.getElementById("userProgress");
      let progressValue = document.getElementById("user-progress-value");
      //let scrollValue = 50;
      progress.style.background = `conic-gradient(#008fff ${counter}%, #c0c0ff ${counter}%)`;
      progressValue.textContent = counter + "%";
    };
    //percentage(fillPercent);
  }

  async function getRekryData() {
    let parsedData;
    if (sessionStorage.getItem("rekryData")) {
      //console.log("ei ajeta?")
      parsedData = sessionStorage.getItem("rekryData");
      setRekryDataFunction(parsedData);
    } else {
      await fetch("/api/user/rekrydata/" + sessionStorage.getItem("id") + "/", {
        method: "GET",
      })
        .then(function (response) {
          if (response.ok) {
            return response.json();
          }
        })
        .then(async function (data) {
          //console.log("asdasd",data)
          setRekryDataFunction(data);
        })
        .catch((error) => {
          console.log("error: " + error);
        });
    }
  }

  function setRekryDataFunction(data) {
    let parsedData = JSON.parse(data);
    setRekryData(parsedData);
    sessionStorage.setItem("rekryData", data);
    let counter = 0;

    let fillableData = [
      "ajoKortti",
      "asema",
      "haettavatTeht",
      "hakuAlat",
      "hakuAlueet",
      "liikkuminen",
      "mahdPalkkatuki",
      "mahdTyokokeilu",
      "omaKuvaus",
      "onkoAuto",
      "paikkaKunnat",
      "palkkaToive",
      "prioriteetti",
      "rajoitteet",
      "tyoLupa",
      "tyonHakija",
      "vaihtoEhdot",
    ];

    for (const item in parsedData) {
      if (parsedData[item].value) {
        //console.log("asd", parsedData[item])
        if (fillableData.includes(item.toString())) {
          counter++;
        }
      }
    }
    let rekryCounter = counter;
    let rekryLength = 17;

    let fillPercent = Math.round((rekryCounter / rekryLength) * 100);

    let percentage = (counter) => {
      let progress = document.getElementById("rekryProgress");
      let progressValue = document.getElementById("rekry-progress-value");
      //let scrollValue = 50;

      progress.style.background = `conic-gradient(#008fff ${counter}%, #c0c0ff ${counter}%)`;
      progressValue.textContent = counter + "%";
    };

    //percentage(fillPercent);
  }

  function ProgressCalculator(
    projectStartDate,
    projectType,
    projectEndDate,
    startTitle
  ) {
    let currentDate = new Date();
    let endDate;
    let startDate;

    if (projectType && projectType === "Valmennus") {
      startDate = new Date(projectStartDate);

      let helpCurrentDate = new Date();

      let helpStartDate = new Date(projectStartDate);
      endDate = new Date(helpStartDate.setMonth(helpStartDate.getMonth() + 3));
      setGuessedEndDate({
        startDate: startDate.toLocaleDateString("fi-FI"),
        endDate: endDate.toLocaleDateString("fi-FI"),
        startTitle: startTitle + startDate.toLocaleDateString("fi-FI"),
      });

      const differenceInMilliseconds = endDate - currentDate;
      const oneMonthInMilliseconds = 30 * 24 * 60 * 60 * 1000;

      if (differenceInMilliseconds >= oneMonthInMilliseconds) {
      } else {
        endDate = new Date(
          helpCurrentDate.setMonth(helpCurrentDate.getMonth() + 1)
        );
      }
    } else if (projectType && projectType != "Valmennus") {
      startDate = new Date(projectStartDate);
      endDate = new Date(projectEndDate);
      setGuessedEndDate({
        startDate: startDate.toLocaleDateString("fi-FI"),
        endDate: endDate.toLocaleDateString("fi-FI"),
        startTitle: startTitle + startDate.toLocaleDateString("fi-FI"),
      });
      setStartEndTitle(
        `AloitusPVM: ${startDate.toLocaleDateString(
          "fi"
        )}, PäättymisPVM: ${endDate.toLocaleDateString("fi")}`
      );
    }

    let totalDuration = endDate - startDate;
    let currentDuration = currentDate - startDate;

    let calculateProgress = (currentDuration / totalDuration) * 100;

    if (Math.round(calculateProgress) > 100) {
      setProgress(100);
    } else if (Math.round(calculateProgress) === 0) {
      setProgress(1);
    } else {
      setProgress(Math.round(calculateProgress));
    }
  }

  function handleCardClick(url, item) {
    //url = "https://vm999.edu.arffman.fi"

    let obj = { item };

    const response = fetch(
      "/api/misc/webMaterialClick/" + sessionStorage.getItem("id"),
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(obj),
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        console.log(url);
        window.open(url, "_blank");
      })
      .catch((error) => {
        console.log("error: " + error);
      });
  }

  async function handleCardClose() {
    setShowIframe(false);
    setIframeUrl("");
  }

  async function getWebMaterials(refresh) {
    setWebMloading(true);
    if (!sessionStorage.getItem("webMaterials") || refresh) {
      setWebMaterials(null);
      const response = await fetch(
        "/api/misc/webmaterials/" + sessionStorage.getItem("id") + "/",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then(function (response) {
          //console.log(response)
          if (response.ok) {
            return response.json();
          }
        })
        .then(async function (data) {
          //console.log("Verkkomateriaalit:", data);
          if (data[0] != "NO_DATA") {
            sessionStorage.setItem("webMaterials", JSON.stringify(data));
            setWebMaterials(data);
          } else {
            setWebMaterials("NO_DATA");
          }
          setWebMloading(false);
        })
        .catch((error) => {
          console.log("error: " + error);
          setWebMloading(false);
        });
    } else {
      setWebMaterials(JSON.parse(sessionStorage.getItem("webMaterials")));
      setWebMloading(false);
    }
  }

  const getAssessments = async (personId, projectId) => {
    const obj = { personId, projectId };

    const response = fetch(
      "/api/user/getAssessments/" + sessionStorage.getItem("id"),
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(obj),
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
       
        data.assessments.sort((a, b) => new Date(b.PVM) - new Date(a.PVM));
        console.log("Kielitaitoarviot:", data);
        sessionStorage.setItem("assessments", JSON.stringify(data.assessments));
        setAssessments(data.assessments);
      })
      .catch((error) => {
        console.log("error: " + error);
      });
  };

  //console.log(webMloading);

  useEffect(() => {
    getUserData();
    getRekryData();
    getWebMaterials();
    getWebinars();
  }, []);

  useEffect(() => {
    if (sessionStorage.getItem("userData")) {
      getAssessments(
        JSON.parse(sessionStorage.getItem("userData")).searchRecId.value,
        JSON.parse(sessionStorage.getItem("userData")).projectId.value
      );
    }
  }, [sessionStorage.getItem("userData")]);

  async function showAllProjects() {
    setLoading(true);
    setShowProjectSelection(true);
    try {
      await getAllProjects();
    } catch (e) {
      console.log("error", e);
    } finally {
      setLoading(false);
    }
  }

  async function getAllProjects() {
    if (sessionStorage.getItem("allprojects"))
      setAllProjects(JSON.parse(sessionStorage.getItem("allprojects")));
    else {
      const response = await fetch(
        "/api/misc/allProjects/" + sessionStorage.getItem("id") + "/",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(),
        }
      )
        .then(function (response) {
          if (!response.ok) throw new Error(JSON.parse(response.body));
          return response.json();
        })
        .then(function (data) {
          let b = JSON.stringify(data);
          sessionStorage.setItem("allprojects", b);
          //console.log(data)
          setAllProjects(data);
        })
        .catch((error) => {
          console.log("error: " + error);
        });
    }
  }

  async function getCustomProject(choice) {
    setWebMloading(true);
    setCustomLoading(true);
    setWebMaterials(null);
    console.log("sdfuhibhibjuibn");
    const obj = { choice };
    const response = await fetch(
      "/api/misc/customUserData/" + sessionStorage.getItem("id") + "/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(obj),
      }
    )
      .then(function (response) {
        if (!response.ok) throw new Error(JSON.parse(response.body));
        return response.json();
      })
      .then(function (data) {
        console.log(data);

        const handleChange = (userDataA) => {
          setUserData({
            ...userData,
            ["projectName"]: data.projectName,
            ["serviceLinks"]: data.serviceLinks,
            ["projectLinks"]: data.projectLinks,
            mainTeacher: {
              ...userData.mainTeacher, // Spread the existing mainTeacher properties
              ["name"]: data.instructor.value.name, // Update the specific name property
              ["email"]: data.instructor.value.email,
              ["phone"]: data.instructor.value.phone,
            },
          });
        };
        handleChange(userData);
        let startDate;
        if (data.projectStartDate) {
          startDate = "Palvelun aloituspäivä on ";
        }
        if (!data.projectStartDate) {
          startDate = "Tarkka aloituspäivä puuttuu. Päiväksi asetettiin ";
        }
        ProgressCalculator(
          data.projectStartDate.value,
          data.serviceType.value,
          data.projectEndDate.value,
          startDate
        );

        setProjectLinks(data.projectLinks);
        setServiceLinks(data.serviceLinks);
        setWebMaterials(data.webMaterials);
        setWebMloading(false);
      })
      .catch((error) => {
        setWebMloading(false);
        console.log("error: " + error);
      });
    setCustomLoading(false);
  }

  return (
    <>
      <Container
        sx={{
          ...containerStyle,
          bgcolor: "white",

          zIndex: "0!important",
          minHeight: "85dvh",
          paddingTop: {
            xs: "0px", // for extra-small screens
            md: "27px",
          },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Modal
          size="xl"
          className="deviceModal"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={showForce}
        >
          <Modal.Header style={{ alignSelf: "center" }}>
            <Modal.Title>Vastattavat lomakkeet</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ textAlign: "center" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {forceAnswers && (
                <>
                  {forceAnswers.map((item, index) => {
                    //console.log(item.formName, item.answers);
                    return (
                      <>
                        <SingleForm form={item} refresh={getForms} />
                      </>
                    );
                  })}
                </>
              )}
            </div>
          </Modal.Body>
        </Modal>

        {projectForms && projectForms.some((e) => !e.answers) && (
          <>
            <Paper
              sx={{
                padding: "20px",
                bgcolor: "white",
                borderRadius: "10px",
                width: "95%",
                height: "100%",
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
              elevation={3}
            >
              {projectForms.map((item, index) => {
                //console.log(item.formName, item.answers);
                return (
                  <>
                    {item && !item.answers && !item.forceAnswer ? (
                      <>
                        <SingleForm
                          form={item}
                          refresh={getForms}
                          getUserData={getUserData}
                        />
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                );
              })}
            </Paper>
          </>
        )}

        <Grid
          container
          spacing={5}
          sx={{
            padding: "30px",
            paddingTop: "0px",
            textAlignLast: "center",
            marginTop: "-5px",
          }}
        >
          <Grid item xs={12} sx={{ paddingTop: "0px!important" }}>
            {whatsNewState ? (
              <>
                <WhatsNew
                  whatsNew={whatsNewState}
                  setWhatsNew={setWhatsNewState}
                  open={open}
                  setOpen={setOpen}
                  version={version}
                />
              </>
            ) : (
              <></>
            )}
          </Grid>

          <Grid item xs={12} lg={6}>
            <Paper
              sx={{
                padding: "20px",
                bgcolor: "white",
                borderRadius: "10px",
                width: "100%",
                height: "100%",
              }}
              elevation={3}
            >
              <Grid container>
                <ServiceInformationGrid
                  showAllProjects={showAllProjects}
                  allProjects={allProjects}
                  loading={loading}
                  getCustomProject={getCustomProject}
                  userData={userData}
                  customLoading={customLoading}
                  showProjectSelection={showProjectSelection}
                  setShowProjectSelection={setShowProjectSelection}
                  projectLinks={projectLinks}
                  guessedEndDate={guessedEndDate}
                  progress={progress}
                  webinars={webinars}
                  assessments={assessments}
                />
              </Grid>

              <Grid item xs={12} sx={{ textAlign: "center" }}>
                <Grid sx={{ padding: "15px" }} item xs={12}>
                  <Divider variant="middle" />
                </Grid>
                <Typography variant="h2" style={{ color: "black!important" }}>
                  {t("home.webinaarit.title")}
                </Typography>

                {webinars ? (
                  <>
                    <div className="webinar-wrapper">
                      {Object.keys(webinars).map((month) => (
                        <>
                          <SingleWebinarMonth
                            month={month}
                            webinars={webinars}
                          />
                          <Box sx={{ padding: "5px" }}>
                            <Divider variant="middle" />
                          </Box>
                        </>
                      ))}
                    </div>
                  </>
                ) : (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        placeContent: "center",
                        marginTop: "50px",
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  </>
                )}
              </Grid>
            </Paper>
          </Grid>

          <Grid
            item
            xs={12}
            lg={6}
            sx={
              {
                //paddingLeft: "10vw!important",
                //paddingRight: "10vw!important",
              }
            }
          >
            <Paper
              sx={{
                padding: "20px",
                bgcolor: "white",
                borderRadius: "10px",
                width: "100%",
                height: "100%",
              }}
              elevation={3}
            >
              <Box
                sx={{
                  display: "flex",
                  marginBottom: "30px",
                  gap: "10px",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Grid
                  item
                  xs={12}
                  sx={{
                    height: {
                      lg: "200px!important",
                      md: "200px!important",
                      xs: "100px!important",
                    },
                  }}
                >
                  <Typography
                    variant="h2"
                    component="h1"
                    sx={{
                      color: "#f28a6c",
                      fontSize: {
                        xs: "2rem", // for extra-small screens
                        md: "3.5rem",
                      },
                    }}
                  >
                    {t("palvelusi.verkkomateriaali.title1")}
                  </Typography>

                  <Typography
                    variant="h2"
                    component="h1"
                    sx={{
                      fontSize: {
                        xs: "1.85rem", // for extra-small screens
                        md: "3.5rem",
                      },
                    }}
                  >
                    {t("palvelusi.verkkomateriaali.title2")}
                  </Typography>
                  <ArrowClockwise
                    onClick={() => getWebMaterials(true)}
                    size={24}
                    style={{
                      marginTop: "10px",
                      cursor: "pointer",
                    }}
                  />
                </Grid>
              </Box>
              <Grid container spacing={2}>
                {webMaterials && webMaterials[0] && !webMloading ? (
                  <>
                    {webMaterials.map((item) => (
                      <WebMaterialCard
                        item={item}
                        handleCardClick={handleCardClick}
                      />
                    ))}
                  </>
                ) : webMloading ? (
                  <Grid item xs={12} sx={{}}>
                    <Box
                      sx={{
                        display: "flex",
                        placeContent: "center",
                        marginTop: "50px",
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  </Grid>
                ) : (
                  <Grid item xs={12}>
                    {t("home.verkkomateriaali.unavailable")}
                  </Grid>
                )}
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default WelcomePage;
/*


              
*/
