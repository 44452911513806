import React, { useState, useEffect } from "react";
import { XCircleFill } from "react-bootstrap-icons";
import { XLg } from "react-bootstrap-icons";

function Dropdown({
  userData,
  field,
  AddToList,
  deleteFromList,
  handleSubmit,
  editFunction,
  cancelEdit,
  picklist,
}) {
  const [pickListState, setpickListState] = useState(picklist[0]);
  useEffect(() => {
    if (sessionStorage.getItem("lang") === "fi") {
      setpickListState(picklist[0]);
    } else {
      setpickListState(picklist[1]);
    }
  });

  return (
    <>
      {userData && userData[field] ? (
        <>
          {userData && userData[field].checked ? (
            <>
              <div style={{ justifyContent: "right" }}>
                <div style={{ display: "flex", justifyContent: "right" }}>
                  <div className="dropDownDiv">
                    <div style={{ textAlign: "right" }}>
                      <select
                        className="multiSelect"
                        onChange={(e) =>
                          AddToList(e.target.value, userData[field])
                        }
                      >
                        {pickListState.map((name, index) => (
                          <option value={picklist[0][index]} key={name}>
                            <p>{name}</p>
                          </option>
                        ))}
                      </select>
                    </div>
                    <div style={{ alignSelf: "center" }}>
                      <button
                        className="cancelButton"
                        onClick={(e) => cancelEdit(userData[field])}
                      >
                        <XLg size={"20"} />
                      </button>
                    </div>
                  </div>
                </div>

                <div style={{ display: "flex", flexDirection: "column" }}>
                  {userData[field].value ? (
                    <>
                      {userData[field].value.map((item, i) => (
                        <div className="dropDownDiv" key={item}>
                          <div style={{ textAlign: "right" }}>{item}</div>
                          <div style={{ alignSelf: "center" }}>
                            <button
                              value={item}
                              className="removeButton"
                              onClick={(e) =>
                                deleteFromList(item, userData[field].objName)
                              }
                            >
                              <XCircleFill color={"red"} />
                            </button>
                          </div>
                        </div>
                      ))}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </>
          ) : (
            <button
              onClick={(e) => editFunction(userData[field])}
              style={{ background: "none", borderRadius: "8px" }}
            >
              {userData[field].value && userData[field].value.length > 0
                ? userData[field].value.join(", ")
                : "-"}
            </button>
          )}
        </>
      ) : (
        <>
          <button
            onClick={(e) => editFunction(userData[field])}
            style={{ background: "none", borderRadius: "8px" }}
          >
            -
          </button>
        </>
      )}
    </>
  );
}

export default Dropdown;
