import { react } from "react";
import { useState, useEffect } from "react";
import {
  ArrowRightShort,
  CaretDownFill,
  CaretRightFill,
  Check,
  Check2,
  CheckLg,
  ColumnsGap,
  PencilFill,
  PlusCircleFill,
  XCircleFill,
} from "react-bootstrap-icons";
import Collapse from "react-bootstrap/Collapse";
import {
  IconButton,
  MenuItem,
  CircularProgress,
  Select,
  FormControl,
  InputLabel,
  Box,
  TextField,
  Button,
  ListSubheader,
} from "@mui/material";
import { Modal } from "react-bootstrap";
import {
  Ammattialat,
  Ammatit,
  Lupakortit_ja_osaamispassit,
  Ty_kokemusta_yhteens,
  Tyonhakualat,
  Hakualueet,
  Palkkatoive,
  Paikkakunnat,
  HaettavatTeht,
  Asema,
  Ty_nhaun_prioriteetti,
  Tyomatka,
  Rajoitteet,
  Ajokortti,
  Tyokokeilu,
  Palkkatuki,
  Vaihtoehdot,
  Tyolupa,
  Auto,
  Ty_lupa_ja_pankkitili,
  Koulutusaste,
  Koulutusala,
} from "../../picklists";
import { toast } from "react-toastify";
import { Tooltip } from "react-tippy";

function SingleForm({ form, refresh, getUserData }) {


  const [open, setOpen] = useState(false);
  const [noteAnswers, setNoteAnswers] = useState({
    checkbox: [],
    radio: [],
    open: [],
  });
  const [zohoFieldAnswers, setZohoFieldAnswers] = useState([]);
  const [unansweredQuestions, setUnansweredQuestions] = useState({});
  const [saveClicked, setSaveClicked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState(
    JSON.parse(sessionStorage.getItem("userData"))
  );
  const [rekryData, setRekryData] = useState(
    JSON.parse(sessionStorage.getItem("userData"))
  );
  const [allUserData, setAllUserData] = useState({
    ...JSON.parse(sessionStorage.getItem("userData")),
    ...JSON.parse(sessionStorage.getItem("rekryData")),
  });

  const notifyFail = (msg) =>
    toast.error(msg, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const notifyMissing = (msg) =>
    toast.info(msg, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const notifySuccess = (msg) =>
    toast.success(msg, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const pickListMapping = {
    Ammattialat: Ammattialat[0],
    Ammatit: Ammatit[0],
    Lupakortit_ja_osaamispassit: Lupakortit_ja_osaamispassit[0],
    Ty_kokemusta_yhteens: Ty_kokemusta_yhteens[0],
    Tyonhakualat: Tyonhakualat[0],
    Ty_nhakualueet_x: Hakualueet[0],
    Palkkatoive_kk: Palkkatoive[0],
    Ty_skentelypaikkakunnat: Paikkakunnat[0],
    Haettavat_teht: HaettavatTeht[0],
    Asema: Asema[0],
    Ty_nhaun_prioriteetti: Ty_nhaun_prioriteetti[0],
    Kuinka_kauas_valmis_liikkumaan: Tyomatka[0],
    Ty_nhaun_rajoitteet: Rajoitteet[0],
    Ajokortti: Ajokortti[0],
    Mahdollisuus_ty_kokeiluun: Tyokokeilu[0],
    Mahdollisuus_palkkatukeen: Palkkatuki[0],
    Muut_ty_nhaun_vaihtoehdot: Vaihtoehdot[0],
    Tyolupa: Tyolupa[0],
    Auto_k_ytett_viss: Auto[0],
    Ty_lupa_ja_pankkitili: Ty_lupa_ja_pankkitili[0],
    Koulutusaste: Koulutusaste[0],
    Koulutusala: Koulutusala[0],
  };

  const handleDeleteSelect = (value, answer) => {
    let newArr = [...zohoFieldAnswers];
    const answerIndex = newArr.findIndex(
      (a) => a.apiName.value === answer.apiName.value
    );

    if (answerIndex !== -1) {
      // Check if the value is an array and filter out the value to be deleted
      if (Array.isArray(newArr[answerIndex].value)) {
        newArr[answerIndex].value = newArr[answerIndex].value.filter(
          (val) => val !== value
        );
      }

      // Update the state
      setZohoFieldAnswers(newArr);
    }
  };

  const saveForm = () => {
    setLoading(true);
    setSaveClicked(true);
    let allQuestionsAnswered = true;

    Object.keys(form.formJSON).forEach((groupKey) => {
      Object.keys(form.formJSON[groupKey].questions).forEach((questionKey) => {
        const question = form.formJSON[groupKey].questions[questionKey];

        // Only check required questions
        if (question.required) {
          switch (question.type) {
            case "zoho":
              if (
                !zohoFieldAnswers.some(
                  (item) => item.apiName.value === question.apiName.value
                )
              ) {
                allQuestionsAnswered = false;
              }
              break;
            case "checkbox":
              if (
                !noteAnswers.checkbox.some(
                  (item) =>
                    item.question === question.label && item.values.length > 0
                )
              ) {
                allQuestionsAnswered = false;
              }
              break;
            case "radio":
              if (
                !noteAnswers.radio.some(
                  (item) => item.question === question.label
                )
              ) {
                allQuestionsAnswered = false;
              }
              break;
            case "open":
              if (
                !noteAnswers.open.some(
                  (item) =>
                    item.question === question.label && item.value.trim() !== ""
                )
              ) {
                allQuestionsAnswered = false;
              }
              break;
            default:
              // Handle other types if necessary
              break;
          }
        }
      });
    });

    if (allQuestionsAnswered) {
      // Proceed with form submission or further processing

      let obj = {
        zohoFieldAnswers,
        noteAnswers,
        id: JSON.parse(sessionStorage.getItem("userData")).searchRecId.value,
        formTitle: form.formName,
        formId: form.linkId,
      };
      const response = fetch(
        "/api/user/submitFormAnswer/" + sessionStorage.getItem("id"),
        {
          method: "POST",
          body: JSON.stringify(obj),
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
       
          if (data.status === "Success") {
            notifySuccess("Vastaus tallennettu!");
            setSaveClicked(false);
            refresh();
            getUserData("reFetch");
          } else {
            notifyFail(
              "Jokin meni vikaan. Yritä uudelleen ja ota tarvittaessa yhteys sovelluskehitys@edu.arffman.fi"
            );
          }
          setLoading(false);
        })
        .catch((error) => {
          console.log("error: " + error);
          notifyFail(
            "Jokin meni vikaan. Yritä uudelleen ja ota tarvittaessa yhteys sovelluskehitys@edu.arffman.fi"
          );
          setLoading(false);
          //notifyError();
        });
    } else {
      notifyFail(
        "Lomakkeessa on täyttämättömiä pakollisia kenttiä. Kentät ovat merkitty punaisella."
      );
      setLoading(false);
    }
  };

  const handleFilledFields = async () => {
    let newArr = [];
    Object.keys(form.formJSON).forEach((groupKey) => {
      const group = form.formJSON[groupKey];
      //console.log(`Group: ${groupKey}, Label: ${group.label}`);

      Object.keys(group.questions).forEach((questionKey) => {
        const question = group.questions[questionKey];
        if (question.type === "zoho") {
          let foundItem = Object.values(allUserData).find(
            (item) => item.name === question.apiName.value
          );
          if (foundItem) {
            if (question.apiName.type === "text") {
              console.log("text:", foundItem)

              newArr.push({
                apiName: question.apiName,
                value: foundItem.value,
              });
            } else if (question.apiName.type === "multiselect") {
              console.log("multi:", foundItem)
              newArr.push({
                apiName: question.apiName,
                value: foundItem.value ? foundItem.value : [],
              });
            } else if (question.apiName.type === "singleselect") {
              console.log("single:", foundItem)
              newArr.push({
                apiName: question.apiName,
                value: foundItem.value,
              });
            }

          
            setZohoFieldAnswers(newArr);
          }
        }
      });
    });
  };

  useEffect(() => {
    handleFilledFields();
  }, []);


  const handleAnswer = (value, question, type, apiName, event) => {
    console.log(value)
    //
    if (type === "zoho") {
      
      let newArr = [...zohoFieldAnswers];
      
      const index = newArr.findIndex(
        (item) => item.apiName.value === apiName.value
      );

      if (index !== -1) {
        if (apiName.type === "multiselect") {
          console.log(newArr)
          // If it's a multiselect and the item already exists, push the new value into the array
          if (newArr[index].value && !newArr[index].value.includes(value)) {
            newArr[index].value.push(value);
          }
        } else {
          // Update the value of the existing object for other types
          newArr[index].value = value;
        }
      } else {
        // Add the new object
        if (apiName.type === "multiselect") {
          newArr.push({ apiName, value: [value] }); // For multiselect, initialize with an array containing the value
        } else {
          newArr.push({ apiName, value });
        }
      }

  
      setZohoFieldAnswers(newArr);
    } else if (type === "checkbox") {
   
      let newArr = { ...noteAnswers };

      const questionIndex = newArr.checkbox.findIndex(
        (item) => item.question === question
      );

      if (event.target.checked) {
        // Checkbox is checked
        if (questionIndex !== -1) {
          // If question already exists in newArr.checkbox
          if (!newArr.checkbox[questionIndex].values.includes(value)) {
            newArr.checkbox[questionIndex].values.push(value);
          }
        } else {
          // If question does not exist, create new entry
          newArr.checkbox.push({ question, values: [value] });
        }
      } else {
        // Checkbox is unchecked
        if (questionIndex !== -1) {
          // Remove value from the existing question's values array
          newArr.checkbox[questionIndex].values = newArr.checkbox[
            questionIndex
          ].values.filter((val) => val !== value);

          // Optionally, remove the question entry if no values are left
          if (newArr.checkbox[questionIndex].values.length === 0) {
            newArr.checkbox.splice(questionIndex, 1);
          }
        }
      }

      setNoteAnswers(newArr);
    } else if (type === "radio") {
   
      let newArr = { ...noteAnswers };

      const questionIndex = newArr.radio.findIndex(
        (item) => item.question === question
      );

      if (questionIndex !== -1) {
        // Update the value for the existing radio question
        newArr.radio[questionIndex].value = value;
      } else {
        // If question does not exist, add a new entry
        newArr.radio.push({ question, value });
      }

      setNoteAnswers(newArr);
    } else if (type === "open") {
    
      let newArr = { ...noteAnswers };

      // Check if the question already exists
      const questionIndex = newArr.open.findIndex(
        (item) => item.question === question
      );

      if (questionIndex !== -1) {
        // Update the existing question's value
        newArr.open[questionIndex].value = value;
      } else {
        // Add a new object for the question if it doesn't exist
        newArr.open.push({ question, value });
      }

      setNoteAnswers(newArr);
    }
  };

  return (
    <>
      <div
        onClick={() => setOpen(!open)}
        className="formListDiv"
        style={{ backgroundColor: "#7db5da6c" }}
      >
        <h4 style={{ marginBottom: "0px" }}>
          {form.formName} {!open ? <CaretRightFill /> : <CaretDownFill />}
        </h4>
      </div>
      <Collapse in={open}>
        <div
          style={{
            width: "75%",
            backgroundColor: "rgba(110, 110, 110, 0.10)",
            padding: "2rem",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <p>
              <strong style={{ color: "red" }}>*</strong> = Pakollinen kysymys
            </p>
            {Object.keys(form.formJSON).map((groupKey) => (
              <div key={groupKey} className="formQuestionGroup">
                {form.formJSON[groupKey].label && (
                  <>
                    <h2>{form.formJSON[groupKey].label}</h2>
                    <hr style={{ color: "salmon" }} />
                  </>
                )}

                {Object.keys(form.formJSON[groupKey].questions).map(
                  (questionKey) => {
                    const question =
                      form.formJSON[groupKey].questions[questionKey];

                    //console.log(question, zohoFieldAnswers);

                    const answer =
                      question.apiName &&
                      question.apiName.type === "multiselect"
                        ? zohoFieldAnswers.find(
                            (a) => a.apiName.value === question.apiName.value
                          )
                        : null;

                    

                    return (
                      <div key={questionKey}>
                        <h3>
                          {question.label}{" "}
                          <strong style={{ color: "red" }}>
                            {question.required && "*"}
                          </strong>
                        </h3>
                        {question.type === "zoho" && (
                          <>
                            {question.apiName.type === "singleselect" ? (
                              <>
                                <FormControl
                                  variant="standard"
                                  sx={{ m: 1, minWidth: "50%", mb: "2rem" }}
                                >
                                  <InputLabel id="type-label">
                                    Valitse vaihtehto
                                  </InputLabel>
                                  <Select
                                    labelId="zoho-select-label"
                                    id="zoho-simple-select"
                                    label="Kysymyksen tyyppi"
                                    value={
                                      zohoFieldAnswers.find(
                                        (e) =>
                                          e.apiName.value ===
                                          question.apiName.value
                                      )
                                        ? pickListMapping[
                                            question.apiName.value
                                          ][
                                            pickListMapping[
                                              question.apiName.value
                                            ].indexOf(
                                              zohoFieldAnswers.find(
                                                (e) =>
                                                  e.apiName.value ===
                                                  question.apiName.value
                                              ).value
                                            )
                                          ]
                                        : null
                                    }
                                    sx={{
                                      border:
                                        saveClicked &&
                                        question.required &&
                                        !zohoFieldAnswers.some(
                                          (e) =>
                                            e.apiName.value ===
                                            question.apiName.value
                                        )
                                          ? "1px solid red"
                                          : undefined,
                                      borderRadius:
                                        saveClicked &&
                                        question.required &&
                                        !zohoFieldAnswers.some(
                                          (e) =>
                                            e.apiName.value ===
                                            question.apiName.value
                                        )
                                          ? "3px"
                                          : undefined,
                                      backgroundColor:
                                        saveClicked &&
                                        question.required &&
                                        !zohoFieldAnswers.some(
                                          (e) =>
                                            e.apiName.value ===
                                            question.apiName.value
                                        )
                                          ? "rgba(255, 50, 50, 0.103)"
                                          : undefined,
                                    }}
                                    onChange={(e) =>
                                      handleAnswer(
                                        e.target.value,
                                        question.label,
                                        question.type,
                                        question.apiName
                                      )
                                    }
                                  >
                                    {Object.entries(
                                      pickListMapping[question.apiName.value]
                                    ).map(([key, value]) => {
                                      //console.log(zohoFieldAnswers)
                                      //console.log(zohoFieldAnswers.find(e => e.apiName.value === question.apiName.value))
                                      return (
                                        <MenuItem value={value}>
                                          {value}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                              </>
                            ) : question.apiName.type === "multiselect" ? (
                              <>
                                <FormControl
                                  variant="standard"
                                  sx={{ m: 1, minWidth: "50%", mb: "2rem" }}
                                >
                                  <InputLabel id="type-label">
                                    Valitse vaihtehto
                                  </InputLabel>
                                  <Select
                                    labelId="zoho-select-label"
                                    id="zoho-simple-select"
                                    value={
                                      pickListMapping[question.apiName.value]
                                        .value
                                    }
                                    label="Kysymyksen tyyppi"
                                    sx={{
                                      border:
                                        saveClicked &&
                                        question.required &&
                                        !zohoFieldAnswers.some(
                                          (e) =>
                                            e.apiName.value ===
                                            question.apiName.value
                                        )
                                          ? "1px solid red"
                                          : undefined,
                                      borderRadius:
                                        saveClicked &&
                                        question.required &&
                                        !zohoFieldAnswers.some(
                                          (e) =>
                                            e.apiName.value ===
                                            question.apiName.value
                                        )
                                          ? "3px"
                                          : undefined,
                                      backgroundColor:
                                        saveClicked &&
                                        question.required &&
                                        !zohoFieldAnswers.some(
                                          (e) =>
                                            e.apiName.value ===
                                            question.apiName.value
                                        )
                                          ? "rgba(255, 50, 50, 0.103)"
                                          : undefined,
                                    }}
                                    onChange={(e) =>
                                      handleAnswer(
                                        pickListMapping[question.apiName.value][e.target.value],
                                        question.label,
                                        question.type,
                                        question.apiName
                                      )
                                    }
                                  >
                                    {Object.entries(
                                      pickListMapping[question.apiName.value]
                                    ).map(([key, value]) => (
                                      <MenuItem key={key} value={key}>
                                        {value}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                                {answer && answer.value && (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div style={{ width: "fit-content" }}>
                                      {answer.value.map((item, index) => (
                                        <div
                                          style={{
                                            display: "flex",
                                            gap: "10px",
                                            placeContent: "center",
                                          }}
                                        >
                                          <p>{item}</p>
                                          <XCircleFill
                                            style={{
                                              marginTop: "2px",
                                              cursor: "pointer",
                                            }}
                                            size={18}
                                            onClick={() =>
                                              handleDeleteSelect(item, answer)
                                            }
                                          />
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                )}
                              </>
                            ) : (
                              <>
                                <input
                                  style={{
                                    border:
                                      saveClicked &&
                                      question.required &&
                                      !zohoFieldAnswers.some(
                                        (e) =>
                                          e.apiName.value ===
                                          question.apiName.value
                                      )
                                        ? "1px solid red"
                                        : undefined,
                                    borderRadius:
                                      saveClicked &&
                                      question.required &&
                                      !zohoFieldAnswers.some(
                                        (e) =>
                                          e.apiName.value ===
                                          question.apiName.value
                                      )
                                        ? "3px"
                                        : undefined,
                                    backgroundColor:
                                      saveClicked &&
                                      question.required &&
                                      !zohoFieldAnswers.some(
                                        (e) =>
                                          e.apiName.value ===
                                          question.apiName.value
                                      )
                                        ? "rgba(255, 50, 50, 0.103)"
                                        : undefined,
                                  }}
                                  onChange={(e) =>
                                    handleAnswer(
                                      e.target.value,
                                      question.label,
                                      question.type,
                                      question.apiName
                                    )
                                  }
                                  value={
                                    zohoFieldAnswers.find(
                                      (e) =>
                                        e.apiName.value ===
                                        question.apiName.value
                                    )
                                      ? zohoFieldAnswers.find(
                                          (e) =>
                                            e.apiName.value ===
                                            question.apiName.value
                                        ).value
                                      : null
                                  }
                                  type="text"
                                />
                              </>
                            )}
                          </>
                        )}
                        {question.type === "checkbox" && (
                          <>
                            <div
                              style={{
                                border:
                                  saveClicked &&
                                  question.required &&
                                  (noteAnswers.checkbox.length < 1 ||
                                    !noteAnswers.checkbox.some(
                                      (e) => e.question === question.label
                                    ))
                                    ? "1px solid red"
                                    : undefined,
                                borderRadius:
                                  saveClicked &&
                                  question.required &&
                                  (noteAnswers.checkbox.length === 0 ||
                                    !noteAnswers.checkbox.some(
                                      (e) => e.question === question.label
                                    ))
                                    ? "3px"
                                    : undefined,
                                backgroundColor:
                                  saveClicked &&
                                  question.required &&
                                  (noteAnswers.checkbox.length === 0 ||
                                    !noteAnswers.checkbox.some(
                                      (e) => e.question === question.label
                                    ))
                                    ? "rgba(255, 50, 50, 0.103)"
                                    : undefined,
                              }}
                            >
                              {/* Render checkbox options */}
                              {Object.keys(question.options).map(
                                (optionKey) => (
                                  <>
                                    <label
                                      style={{
                                        display: "flex",
                                        placeContent: "center",
                                        gap: "5px",
                                      }}
                                      key={optionKey}
                                    >
                                      <input
                                        type="checkbox"
                                        value={optionKey}
                                        onChange={(e) =>
                                          handleAnswer(
                                            question.options[optionKey],
                                            question.label,
                                            question.type,
                                            null,
                                            e
                                          )
                                        }
                                      />
                                      <p style={{ marginBottom: "0px" }}>
                                        {question.options[optionKey]}
                                      </p>
                                    </label>
                                  </>
                                )
                              )}
                            </div>
                          </>
                        )}
                        {question.type === "radio" && (
                          <div
                            style={{
                              border:
                                saveClicked &&
                                question.required &&
                                (noteAnswers.radio.length < 1 ||
                                  !noteAnswers.radio.some(
                                    (e) => e.question === question.label
                                  ))
                                  ? "1px solid red"
                                  : undefined,
                              borderRadius:
                                saveClicked &&
                                question.required &&
                                (noteAnswers.radio.length === 0 ||
                                  !noteAnswers.radio.some(
                                    (e) => e.question === question.label
                                  ))
                                  ? "3px"
                                  : undefined,
                              backgroundColor:
                                saveClicked &&
                                question.required &&
                                (noteAnswers.radio.length === 0 ||
                                  !noteAnswers.radio.some(
                                    (e) => e.question === question.label
                                  ))
                                  ? "rgba(255, 50, 50, 0.103)"
                                  : undefined,
                            }}
                          >
                            {/* Render radio options */}
                            {Object.keys(question.options).map((optionKey) => (
                              <label
                                style={{
                                  display: "flex",
                                  placeContent: "center",
                                  gap: "5px",
                                }}
                                key={optionKey}
                              >
                                <input
                                  type="radio"
                                  name={questionKey}
                                  value={optionKey}
                                  onChange={(e) =>
                                    handleAnswer(
                                      question.options[optionKey],
                                      question.label,
                                      question.type,
                                      null,
                                      e
                                    )
                                  }
                                />
                                {question.options[optionKey]}
                              </label>
                            ))}
                          </div>
                        )}
                        {question.type === "open" && (
                          <textarea
                            style={{
                              width: "80%",
                              border:
                                saveClicked &&
                                question.required &&
                                (noteAnswers.open.length < 1 ||
                                  !noteAnswers.open.some(
                                    (e) => e.question === question.label
                                  ))
                                  ? "1px solid red"
                                  : undefined,
                              borderRadius:
                                saveClicked &&
                                question.required &&
                                (noteAnswers.open.length === 0 ||
                                  !noteAnswers.open.some(
                                    (e) => e.question === question.label
                                  ))
                                  ? "3px"
                                  : undefined,
                              backgroundColor:
                                saveClicked &&
                                question.required &&
                                (noteAnswers.open.length === 0 ||
                                  !noteAnswers.open.some(
                                    (e) => e.question === question.label
                                  ))
                                  ? "rgba(255, 50, 50, 0.103)"
                                  : undefined,
                            }}
                            placeholder="Kirjoita vastauksesi tähän"
                            onChange={(e) =>
                              handleAnswer(
                                e.target.value,
                                question.label,
                                question.type
                              )
                            }
                          />
                        )}
                        <hr style={{ color: "lightgray" }} />
                      </div>
                    );
                  }
                )}
              </div>
            ))}
            {form.onlyOneReturn ? (
              <>
                <Tooltip
                  theme="dark"
                  size="medium"
                  title={
                    "Huomioithan, että voit vastata lomakkeeseen vain kerran, joten varmistu siitä, että lomakkeen tiedot ovat oikein"
                  }
                  position="top"
                  trigger="mouseenter"
                >
                  {loading ? (
                    <>
                      <Button
                        variant="contained"
                        sx={{ width: "15rem", height: "3rem" }}
                        disabled
                      >
                        <CheckLg
                          size={25}
                          style={{ marginBottom: "3px", marginRight: "5px" }}
                        />
                        <CircularProgress />
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        variant="contained"
                        sx={{ width: "15rem", height: "3rem" }}
                        onClick={() => saveForm()}
                      >
                        <CheckLg
                          size={25}
                          style={{ marginBottom: "3px", marginRight: "5px" }}
                        />
                        Tallenna vastaus
                      </Button>
                    </>
                  )}
                </Tooltip>
              </>
            ) : (
              <>
                {loading ? (
                  <>
                    <Button
                      variant="contained"
                      sx={{ width: "15rem", height: "3rem" }}
                      disabled
                    >
                      <CheckLg
                        size={25}
                        style={{ marginBottom: "3px", marginRight: "5px" }}
                      />
                      <CircularProgress />
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      variant="contained"
                      sx={{ width: "15rem", height: "3rem" }}
                      onClick={() => saveForm()}
                    >
                      <CheckLg
                        size={25}
                        style={{ marginBottom: "3px", marginRight: "5px" }}
                      />
                      Tallenna vastaus
                    </Button>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </Collapse>
    </>
  );
}

export default SingleForm;
