import React, { useState, useEffect, useCallback, useRef } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Dropdown } from "react-bootstrap";
import { Paper, Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import SingleWebinarMonth from "../components/SingleWebinarMonth";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function Home({ handleLogin, setAdmin, login, setInsModal }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [insDiv, setInsDiv] = useState(false);
  const [animationClass, setAnimationClass] = useState(["", "", ""]);
  const [lastScrollY, setLastScrollY] = useState(0);
  const { t, i18n } = useTranslation();
  const boxRefs = [useRef(null), useRef(null), useRef(null)];
  const [webinars, setWebinars] = useState(null);
  const query = useQuery();
  //document.getElementById('Appbod').style.backgroundImage = `none`;

  const notifyError = () =>
    toast.error(
      "Virhe: Sinulla ei ole oikeutta käyttää portaalia. ota yhteyttä palveluasiantuntijaasi.",
      {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );

  useEffect(() => {
    // Check if the query parameter 'activate' exists and set state based on it
    const instructions = query.get("instructions");
    if (instructions === "true") {
      setInsModal(true);
    }
  }, [query]);

  async function handleInsBtn() {
    setInsDiv((current) => !current);
  }

  //notifyNoPermission();
  //  notifyFail()
  async function handleCallbackResponse(response) {
    setLoading(true);
    //console.log("Encoded JWT token:", response.credential);
    console.log(response);
    var userToken = response.credential;
    var userObj = { userToken };
    await fetch("/api/login", {
      method: "POST", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userObj),
    })
      .then(function (response) {
        if (!response.ok) throw new Error(response.status);
        //if (response === "nopermission")
        return response.json();
      })
      .then((data) => {
        //console.log("login data: ",data);
        sessionStorage.setItem("groups", data.userGroups);
        sessionStorage.setItem("user", data.email);
        sessionStorage.setItem("id", data.userid);
        sessionStorage.setItem("image", data.img);
        sessionStorage.setItem("rekryData", "");
        sessionStorage.setItem("userData", "");
        sessionStorage.setItem("version", "");
        sessionStorage.setItem("testUser", data.testUser);
        if (data.admin) {
          sessionStorage.setItem("idhash", true);
        }

        handleLogin();
        setLoading(false);
        handleOnClick();
      })
      .catch((error) => {
        setLoading(false);
        notifyError();
        console.log("error: " + error);
      });
  }

  const handleOnClick = useCallback(
    () => navigate("/palvelusi", { replace: true }),
    [navigate]
  );

  function handleLoginAnchorClick() {
    google.accounts.id.prompt();
  }

  useEffect(() => {
    /* global google */

    if (sessionStorage.getItem("log") !== "true") {
      sessionStorage.setItem("log", "true");
      let apiCallTime = localStorage.getItem("projectsTimestamp");
      let currentTime = new Date().getTime();
      let timeDifference = currentTime - apiCallTime;
      var difference = timeDifference / (1000 * 60 * 60);
      let date = new Date().getTime();

      if (!localStorage.getItem("projectsTimestamp") || difference >= 2) {
        fetch("/api/logs/entry", {
          method: "POST", // or 'PUT'
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then(function (response) {
            if (!response.ok) throw new Error(response.status);
            //if (response === "nopermission")
            return response;
          })
          .then((data) => {
            localStorage.setItem("projectsTimestamp", date);
          });
      }
    }
  });

  useEffect(() => {
    if (window.google && window.google.accounts) {
      google.accounts.id.initialize({
        client_id:
          "396899188355-255v29dh0hktattha7epme2knll6rqm1.apps.googleusercontent.com",
        callback: handleCallbackResponse,
      });

      // Check if the button is already rendered
      const signInButton = document.getElementById("signinDiv");
      if (signInButton && signInButton.innerHTML === "") {
        google.accounts.id.renderButton(signInButton, {
          theme: "outline",
          size: "large",
          ux_mode: 'redirect',
        });
      }
    }
  }, [window?.google]);

  useEffect(() => {
    //console.log("scroll")
    const debouncedHandleScroll = debounce(handleScroll, 10);
    window.addEventListener("scroll", debouncedHandleScroll);
    return () => window.removeEventListener("scroll", debouncedHandleScroll);
  }, []);

  useEffect(() => {
    getWebinars();
  }, []);

  async function getWebinars() {
    const response = await fetch(
      "/api/misc/webinars/" + sessionStorage.getItem("id") + "/",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then(function (response) {
        //console.log(response)
        if (response.ok) {
          return response.json();
        }
      })
      .then(async function (data) {
        setWebinars(data);
      })
      .catch((error) => {
        console.log("error: " + error);
      });
  }
  

  function debounce(fn, wait) {
    let timeout;
    return function (...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => fn(...args), wait);
    };
  }

  function handleScroll() {
    const currentScrollY = window.scrollY;
    boxRefs.forEach((boxRef, index) => {
      const boxTop = boxRef?.current?.offsetTop;
      const isGoingUp = currentScrollY > boxTop - 250;
      let newAnimations = ["", "", ""];
      if (isGoingUp) {
        newAnimations[index] =
          animationClass[index] === "float-up" ? "float-down" : "float-up";
        setAnimationClass(newAnimations);
      }
    });
  }

  const loadingStyle = {
    textAlign: "center",
    color: "white",
    marginTop: "15vh",
    width: "100vw",
    height: "65vh",
  };

  if (loading) {
    return (
      <div id="tableContainerFront" style={loadingStyle}>
        <Spinner style={{ width: "7vh", height: "7vh" }} animation="border" />
      </div>
    );
  }

  return (
    <div id="indexContainer" style={{ paddingBottom: "50px" }}>
      <div id="parasPolkuCont">
        <div id="leftPolku">
          <div id="headerDiv">
            <h2 className="polkuHeader">{t("home.header.Paras")}</h2>
            <h3 className="polkuHeader">{t("home.header.Polku")}</h3>
            <h2 className="polkuHeader">{t("home.header.Työhön")}</h2>
          </div>
        </div>
        <div id="rightPolku">
          {sessionStorage.getItem("user") ? (
            <></>
          ) : (
            <>
              <Paper elevation={3} className="polkuTextDiv">
                <Typography variant="h2">
                  <h2>{t("home.tervetuloa.title")}</h2>
                </Typography>
                <Typography className="polkuText">
                  {t("home.tervetuloa.p1")}
                  <Typography>
                    {t("home.tervetuloa.loginA")}
                    </Typography>
                  {t("home.tervetuloa.p2")}
                  <a className="floatAnchor" onClick={() => setInsModal(true)}>
                    {t("home.tervetuloa.instructA")}
                  </a>{" "}
                  {t("home.tervetuloa.p3")} <br />
                  <br /> {t("home.tervetuloa.p4")}
                </Typography>
              </Paper>
            </>
          )}
        </div>
      </div>

      <div className="floatContainer" ref={boxRefs[0]}>
        <Paper id="textBox" className={`floating-box ${animationClass[0]}`}>
          <h2>{t("home.webinaarit.title")}</h2>
          <p>
            {t("home.webinaarit.p1")}
            <br />
            <br />
            {t("home.webinaarit.p2")}
            <br />
            <br />
            {t("home.webinaarit.p3")}
          </p>

          {webinars ? (
            <div >
      
                {Object.keys(webinars).map((month) => (
                  <SingleWebinarMonth
                    month={month}
                    webinars={webinars}
                    prop={"etusivu"}
                  />
                ))}
            
            </div>
          ) : (
            <></>
          )}

          {/* <Button
            id="floatBtn"
            onClick={() =>
              window.open("https://www.arffman.fi/polku/webinaarit/", "_blank")
            }
          >
            {t("home.webinaarit.p5")}
          </Button> */}
        </Paper>

        <div
          id="imgBox"
          className={
            animationClass[0] === "float-up"
              ? `floating-box float-down`
              : `floating-box float-up`
          }
        >
          <img
            style={{ width: "100%" }}
            src="https://www.arffman.fi/wp-content/uploads/2021/09/polku-page-elements-3-500x375.png"
          />
        </div>
      </div>

      <div className="floatContainer" ref={boxRefs[1]}>
        <Paper id="textBox" className={`floating-box ${animationClass[1]}`}>
          <h2>{t("home.verkkomateriaali.title")}</h2>
          <p>
            {t("home.verkkomateriaali.p1")}
            <br />
            <br />
            {t("home.verkkomateriaali.p2")}
            <br />
            <br />
          </p>
        </Paper>

        <div
          id="imgBox"
          className={
            animationClass[1] === "float-up"
              ? `floating-box float-down`
              : `floating-box float-up`
          }
        >
          <img
            style={{ width: "100%" }}
            src="https://www.arffman.fi/wp-content/uploads/2021/09/polku-page-elements-1-3-500x375.png"
          />
        </div>
      </div>

      <div className="floatContainer" ref={boxRefs[2]}>
        <Paper id="textBox" className={`floating-box ${animationClass[2]}`}>
          <h2>{t("home.youtube.title")}</h2>
          <p>
            {t("home.youtube.p1")}
            <br />
            <br />
            {t("home.youtube.p2")}
            <br />
            <br />
            {t("home.youtube.p3")}
          </p>
          <Button
            id="floatBtn"
            onClick={() =>
              window.open(
                "https://www.arffman.fi/polku/paras-polku-tyohon-youtube-live/",
                "_blank"
              )
            }
          >
            {t("home.youtube.p5")}
          </Button>
        </Paper>

        <div
          id="imgBox"
          className={
            animationClass[2] === "float-up"
              ? `floating-box float-down`
              : `floating-box float-up`
          }
        >
          <img
            style={{ width: "100%" }}
            src="https://www.arffman.fi/wp-content/uploads/2021/09/polku-page-elements-2-500x375.png"
          />
        </div>
      </div>

      <div className="cardContainer">
        <div className="polkuCard">
          <img
            className="cardImg"
            src="https://www.arffman.fi/wp-content/uploads/2021/09/7.jpg"
          />
          <h2>{t("home.foreammatit.title")}</h2>
          <p>
            {t("home.foreammatit.p1")} <br />
            <br />
            {t("home.foreammatit.p2")}
          </p>
          <Button
            onClick={() => window.open("https://www.foreammatti.fi/", "_blank")}
            id="cardButton"
          >
            {t("home.foreammatit.p5")}
          </Button>
        </div>

        <div className="polkuCard">
          <img
            className="cardImg"
            src="https://www.arffman.fi/wp-content/uploads/2021/09/polku-page-elements-.jpg"
          />
          <h2>{t("home.vainu.title")}</h2>
          <p>
            {t("home.vainu.p1")} <br />
            <br />
            {t("home.vainu.p2")}
          </p>
          <Button
            onClick={() => window.open("https://vainu.io/", "_blank")}
            id="cardButton"
          >
            {t("home.vainu.p5")}
          </Button>
        </div>

        <div className="polkuCard">
          <img
            className="cardImg"
            src="https://www.arffman.fi/wp-content/uploads/2021/09/6.jpg"
          />
          <h2>
            {t("home.verkkovalmentaja.title1")}
            <br /> {t("home.verkkovalmentaja.title2")}
          </h2>
          <p style={{ minHeight: "14.25rem" }}>
            {t("home.verkkovalmentaja.p1")} <br />
            <br />
            {t("home.verkkovalmentaja.p2")}
          </p>

          <Dropdown>
            <Dropdown.Toggle id="customDropDown">
              <Button id="cardButton">{t("home.verkkovalmentaja.p5")}</Button>
            </Dropdown.Toggle>
            <Dropdown.Menu style={{ width: "100%", textAlign: "center" }}>
              <Dropdown.Item
                style={{ cursor: "pointer" }}
                target="_blank"
                href="https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ2tO0xURjXX1Hz6uUd1_QE2CavbGSyGau-2b6czCxJsYdVoWkaB5742dKo8ckoSLPKXxzZB7mF_"
              >
                Tiina Saarinen
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>

      <div className="divideCont">
        <div className="divideChild">
          <img
            className="iconImg"
            src="https://www.arffman.fi/wp-content/uploads/2023/01/polu-icons-update-1.png"
          />
          <h2>{t("home.psykologi.title")}</h2>
          <p>
            {t("home.psykologi.p1")} <br />
            <br />
            {t("home.psykologi.p2")}
          </p>
          <hr className="divideHr" />
          <img
            className="iconImg"
            src="https://www.arffman.fi/wp-content/uploads/2023/01/uraohjaaja-polu-icons-update.png"
          />
          <h2>{t("home.uraohjaaja.title")}</h2>
          <p>
            {t("home.uraohjaaja.p1")} <br />
            <br />
            {t("home.uraohjaaja.p2")}
          </p>
        </div>
        <div className="divideChild">
          <img
            className="iconImg"
            src="https://www.arffman.fi/wp-content/uploads/2023/01/tyonetsija-polu-icons-update.png"
          />
          <h2>{t("home.työnetsijä.title")}</h2>
          <p>
            {t("home.työnetsijä.p1")} <br />
            <br />
            {t("home.työnetsijä.p2")}
          </p>
          <hr className="divideHr" />
          <img
            className="iconImg"
            src="https://www.arffman.fi/wp-content/uploads/2023/01/yrittajyysvalmentaja-polu-icons-update.png"
          />
          <h2>{t("home.yrittäjyysvalmentaja.title")}</h2>
          <p>
            {t("home.yrittäjyysvalmentaja.p1")} <br />
            <br />
            {t("home.yrittäjyysvalmentaja.p2")}
          </p>
        </div>
      </div>
      <div className="freeTextDiv">
        <div style={{ textAlign: "center" }}>
          <a href="https://free.fi/" target="_blank">
            <img
              style={{ width: "8rem" }}
              src="https://free.fi/wp-content/themes/FREEfi/assets/free-fi_logo.svg"
            ></img>
          </a>
        </div>
        <h2 style={{ textAlign: "center", marginTop: "2rem" }}>
          {t("home.free2.title")}
        </h2>

        <p className="polkuText">
          <a className="floatAnchor" href="https://free.fi/" target="_blank">
            {t("home.free2.p1a1")}
          </a>
          {t("home.free2.p1")}
        </p>
      </div>

      <div style={{ marginTop: "5%" }}></div>
    </div>
  );
}

export default Home;
