import { Navigate } from 'react-router-dom';
import  React from 'react';


export function PrivateRoute({ children }) {

// the user verification logic is up to your application
// this is an example based on your code above 
    const rememberMe = sessionStorage.getItem("goASifkFD4235");
   if (!rememberMe) {
    return <Navigate to={'/'} />;
    }
  else {
// render the wrapped page
    return children;
}

}
