import React, { useState, useEffect } from "react";
import { Grid, Typography, Paper, Box, Button, Collapse } from "@mui/material";
import placeholder from "../images/placeholder.png";
const WebMaterialCard = ({ item, handleCardClick }) => {
  const [open, setOpen] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);

  const imageStyle = imageLoaded
    ? { transition: "opacity 2s ease-in-out", opacity: 1 }
    : { opacity: 0 };


    useEffect(() => {
      setTimeout(() => {
        if(!imageLoaded) setImageLoaded(true);
      }, 3000);
    }, []);

    

  return (
    <Grid item xs={6}>
      <Paper
        onClick={() => setOpen(!open)}
        elevation={3}
        sx={{
          minHeight: "325px",
          cursor: "pointer",
          ":hover": {
            boxShadow: 20,
          },
        }}
        style={imageStyle}
      >
        <Box>
          <img
            className="webMaterialImg"
            alt="webmimg"
            src={item[0].image}
            onLoad={() => setImageLoaded(true)}
          />

          <Box paddingY={1} height={70}>
            <Typography
              variant="subtitle1"
              component="h1"
              sx={{
                fontSize: {
                  xs: "0.6rem", // for extra-small screens
                  md: "1rem",
                },
                fontWeight: "600",
              }}
            >
              {item[0].title}
            </Typography>
          </Box>

          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box
              paddingX={1}
              sx={{ textAlign: "center", marginBottom: "10px" }}
            >
              <Typography
                variant="subtitle2"
                component="p"
                sx={{
                  fontSize: {
                    xs: "0.6rem", // for extra-small screens
                    md: "1rem",
                  },
                }}
              >
                {item[0].description}
              </Typography>
              <Button
                sx={{ marginTop: "5px" }}
                variant="contained"
                onClick={(e) => {
                  e.stopPropagation();
                  handleCardClick(item[0].url, item[0]);
                }}
              >
                Aloita
              </Button>
            </Box>
          </Collapse>
        </Box>
      </Paper>
    </Grid>
  );
};

export default WebMaterialCard;
