import { Spinner } from "react-bootstrap"

function LoadingSpinner(type) {
   

    const loadingStyleVerySmall = {
        height: '1.25vh',
        width:'1.25vh'
         
           
        };
    
        const loadingStyleSmall = {
        
        };


   

      const loadingStyle = {
        textAlign: 'center',
        marginTop: '25vh'
      };
    

    if(type.type === "small") {
    return (
        <div style={loadingStyleSmall}>
        <Spinner animation="border" />

        </div>
    )
    }
    
    if(type.type === "verySmall") {
        return (
            <div >
            <Spinner animation="border" style={loadingStyleVerySmall}/>
    
            </div>
        )
        }

    if(type.type === "partList") {
        return(
        <div style={loadingStyleSmall}>
            <Spinner animation="border" />
            <p style={{fontSize: '2vh'}}>Projekteja haetaan</p>
        </div>
        )
    }
    if(type.type === "studentList"){
        return(
            <div style={loadingStyleSmall}>
                <Spinner animation="border" />
                <p>Oppilaita haetaan</p>
            </div>
            )
    }
    if(type.type === "teachers") {
        return(
        <div style={loadingStyleSmall}>
            <Spinner animation="border" />
            <p style={{fontSize: '2vh'}}>Työntekijöitä haetaan</p>
        </div>
        )
    }

    if(type.type === "jobAds") {
        return(
        <div style={{width:'15vh', heigth: '15vh'}}>
            <br></br>
            <Spinner animation="border" />
            <br></br>
        </div>
        )
    }

    else {
    return(
        <div style={loadingStyle}>
        <Spinner animation="border" />
    
        </div>
    )
    }
    
}

export default LoadingSpinner;